import { plainToClass } from "class-transformer";
import { DataModel } from "./common.model";

export class Category extends DataModel {
    category:string;
    checked:boolean = false;


    public static fromDTOs(dtos: any[]) {
        let category: Category[] = [];
        for (let entity of dtos) {
            category.push(Category.fromDTO(entity));
        }
        return category;
    }

    public static fromDTO(dto: any) {

    
        const category: Category = plainToClass(Category, dto['acf']);
    
        if(dto['id']) {
            category.id = dto['id'];
        }

        return category;
      }
}