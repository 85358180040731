import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BeResponse } from "app/+state/entity/response.entyt";
import { environment } from "environments/environment";
import { of } from 'rxjs';
import { ErrorService } from './error.service';
import { LoaderService } from './loader.service';

export class CrudService<T> {
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',      
    })
  };

  protected maxDataForPage:string = "4";

  constructor(
    public httpClient: HttpClient,
    public baseUrl: string,
    public endpoint: string,
    public errorService: ErrorService,
    public loaderService: LoaderService
  ) { }


  protected getLinkFromEndpointUrl(subPath:string):string {
    return `${environment.endpoint}`.concat(subPath);
  }


  /*public getList(): Observable<T[]> {
    this.loaderService.show();
    return this.httpClient
      .get(`${this.baseUrl}${this.endpoint}/all`)
      .pipe(
        map((data: any) => data as T[]),
        catchError((err) => {
          this.error(err);
          throw err;
        }),
        finalize(() => this.loaderService.hide()));
  }*/

  protected error(err: any) {
    if (!!err.error.code) {
      this.errorService.showError(err.error.code);
    } else if (!!err.error.message) {
      this.errorService.showError(err.error.message);
    } else {
      this.errorService.showError('Errore Generico');
    }
    return of(null);
  }


  protected checkOkResponse(response:BeResponse):boolean {

    //response.success
    return response.statusCode === "200";
  }

}
