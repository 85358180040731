import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { CrudService } from '../services/core/crud.service';
import { ErrorService } from '../services/core/error.service';
import { LoaderService } from '../services/core/loader.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends CrudService<any> implements OnInit {
    
    public privacy_policy$: Observable<String>; 

    constructor(public httpClient: HttpClient,
        public errorService: ErrorService,
        public loaderService: LoaderService) {
        super(httpClient, `${environment.endpoint}`,'/wp/v2', errorService, loaderService);
       }
    
    ngOnInit() {
        //this.loaderService.show();
        
      
        }   
}
