import { Component, Input, OnInit } from '@angular/core';
import { SeoService } from 'app/shared/services/core/seo.service';

@Component({
  selector: 'seo-title',
  template: '',
})
export class SeoTitleComponent implements OnInit {

  @Input()
  set title(title: string) {
    this.seoService.setTitle(title);
  }

  constructor(
    private seoService: SeoService,
  ) {
  }

  ngOnInit(): void {
  }

}
