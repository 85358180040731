// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.




const backendURL = 'europeanaccademyofarts.local/wp-json';
const backendProtocol = 'https://';

const i18nFolder = '/assets/i18n';

export const environment = {
  production: false,
  endpoint: backendProtocol + backendURL,
  facebookAppId: '415873382802137',
  i18nFolder:i18nFolder,
  siteDomain:backendProtocol + "europeanaccademyofarts.local",
  siteTitle:"Acadèmie Europèenne des Arts"
};

