import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/+state';
import { State } from 'app/+state';
import * as loginActions from 'app/+state/actions/login.actions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();

 
  showModal: boolean;
  loginForm: FormGroup;
  registerForm: FormGroup;
  resetPwdForm : FormGroup;
  submitted = false;

  viewSubscription = false;

  model: User = new User();

  constructor(private router: Router,private formBuilder: FormBuilder,private store: Store<State>) {
    //intercettar eanche login error
    this.store.select(fromRoot.userLogin).pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      console.log('data::::', data);
      if (data.isLoadingSuccess /*&& data.result.statusCode*/) {
        this.router.navigate(['/dashboard']);
      }      
    });

    this.store.select(fromRoot.userFailure).pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      console.log('data::::', data);

      if(data.isLoadingFailure) {
        //visualizza errore
      }
      // if (data.isLoadingSuccess && data.result.statusCode) {
      //   this.router.navigate(['/dashboard']);
      // }      
      //se in errore allora visualizzar eil messaggio di errore
    });

    // this.store.select(fromRoot.signUpSuccess).pipe(
    //   takeUntil(this.destroy$)
    // ).subscribe(data => {
      

    //   //if(data.isLoadingSignUpSuccess) {
    //     //visualizza errore
    //   //  this.viewSubscription = false;
    //   //}
    //   // if (data.isLoadingSuccess && data.result.statusCode) {
    //   //   this.router.navigate(['/dashboard']);
    //   // }      
    //   //se in errore allora visualizzar eil messaggio di errore
    // });

  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
  });

    this.registerForm = this.formBuilder.group({
        username: ['', [Validators.required, Validators.email]],
        email: ['', [Validators.required, Validators.email]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(6)]]
    });

    this.resetPwdForm =  this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  signIn() {
     Object.assign(this.model, this.loginForm.value);
     //this.store.dispatch(loginActions.login({user: { email: this.model.email, password: this.model.password }}));
  }

  signUp() {
    Object.assign(this.model, this.registerForm.value);
    this.store.dispatch(loginActions.signUp({userSignUp: { 
      
      email: this.model.email, 
      password: this.model.password,
      firstName: this.model.firstname,
      lastName: this.model.lastname,
      //username: this.model.username
     }}));
 }


 resetPwdUp() {
  Object.assign(this.model, this.resetPwdForm.value);
  //this.store.dispatch(loginActions.resetPwd({emailUser: this.model.email}));
}

  viewSignUp() {
    this.viewSubscription = true;
 }

 login() {
  this.viewSubscription = false;
}

  ngOnDestroy(){
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
}

}

export class User {

  constructor(

  ) {  }

  public email: string;
  public password: string;
  public firstname:string;
  public lastname:string;
  public username:string;

}



  



// // convenience getter for easy access to form fields
//   get f() { return this.registerForm.controls; }

//   onSubmit() {

//     this.submitted = true;
//     // stop here if form is invalid
//     /*if (this.registerForm.invalid) {
//         return;
//     }*/
//     if(this.submitted)
//     {
//       console.log('sendLogin');
//       const option:UserLoginOptions = <UserLoginOptions>{};
//       Object.assign(option, this.registerForm.value);
//       this.authService.signIn(option);
//     }
//   }


// }
