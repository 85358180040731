import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../environments/environment';
import * as userActions from './actions';
import { User } from './entity';
import { GenericEvent } from './entity/events.entity';
import * as fromArtists from './reducers/artists.reducer';
import * as fromContact from './reducers/contacts.reducer';
import * as fromEvents from './reducers/events.reducer';
import * as fromHome from './reducers/home.reducer';
import * as fromI18n from './reducers/i18n.reducer';
import * as fromJournals from './reducers/journal.reducer';
import * as fromOperas from './reducers/operas.reducer';
import * as fromUser from './reducers/user.reducer';






//divisione dello state manager memorizzato nel local storage proprità dello stato storage.getItem('user').user
const reducerKeys = [
  'user', 
  fromI18n.languageFeatureKey,
  'contact',
  fromEvents.eventsFeatureKey,
  fromArtists.artistsFeatureKey,
  fromOperas.operasFeatureKey,
  fromJournals.journalFeatureKey];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: reducerKeys})(reducer);
}

// console.log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}

//ripulisce tuto lo stato in fase di logout
export function clearState(reducer: ActionReducer<any>) : ActionReducer<any> {
  return function (state, action) {

    if (action.type === userActions.logout.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

//stato applicativo totale
export interface State {
  user: fromUser.State;
  language: fromI18n.State;
  home: fromHome.State;
  contact: fromContact.State;
  events:fromEvents.State;
  artists:fromArtists.State;
  operas:fromOperas.State;
  journals:fromJournals.State,
}

//individua lo stato dal reducer esportato
export const reducers: ActionReducerMap<State> = {
  user: fromUser.reducer,
  language: fromI18n.reducer,
  home: fromHome.reducer,
  contact: fromContact.reducer,
  events: fromEvents.reducer,
  artists: fromArtists.reducer,
  operas:fromOperas.reducer,
  journals:fromJournals.reducer
};

//hydratre l'oggetto nello storage
export const metaReducers: MetaReducer<State>[] = !environment.production ? [debug, localStorageSyncReducer,clearState] : [localStorageSyncReducer,clearState];

/********************************************LOGIN ******************************/
//selectot per l'utente
export const getLoginState = createFeatureSelector<fromUser.State>('user');

//recupero  dal login state se l'utente è loggato
export const getLoggedInUser = createSelector(
  getLoginState,
  fromUser.getLoggedInUser
);

//se l'utente sta eseguendo il login
export const userLogin = createSelector(
  getLoginState,
  fromUser.userLogin
);

export const userFailure = createSelector(
  getLoginState,
  fromUser.userFailure
);


export const signUpSuccess = createSelector(
  getLoginState,
  fromUser.signUpSuccess
);

export const signUpFailure = createSelector(
  getLoginState,
  fromUser.signUpFailure
);


export const resetPwdFailure = createSelector(
  getLoginState,
  fromUser.signUpFailure
);

export const getUserId = createSelector(
  getLoginState,
  fromUser.getUserId
);

export const userHasRoleContributor = createSelector(
  getLoginState,
  fromUser.userHasRoleContributor
);



/********************************************LOGIN ******************************/

/******************************************** I18N ******************************/

//Language i18n, retrive slice for current language
export const getI18nState = createFeatureSelector<fromI18n.State>(fromI18n.languageFeatureKey);
export const getCurrentLanguage = createSelector(
  getI18nState,
  fromI18n.getCurrentLanguage
);

/******************************************** I18N ******************************/


/********************************************* CONTACT ************************* */

export const getContactState = createFeatureSelector<fromContact.State>('contact');

//recupero  dal login state se l'utente è loggato
export const getContact = createSelector(
  getContactState,
  fromContact.getContact
);

export const contactSended =  createSelector(
  getContactState,
  fromContact.contactSended
);


/********************************************* EVENTS ************************* */

export const getEventsState = createFeatureSelector<fromEvents.State>(fromEvents.eventsFeatureKey);

//seleziona la lista degli eventi
export const getGenericEvents = createSelector(
  getEventsState,
  fromEvents.getGenericEvents
);


export const isGenericEventSubscribedToUser = createSelector(
  (state: State) => state.user.user,
  (state: State) => state.events.events,
  (user:User,events:GenericEvent[],eventId) => {

    let subscribed = false;
    if(user && events && events.length > 0) {
        const event = events.find(e => e.id === eventId.id && e.users_subscribed && e.users_subscribed.length > 0 && e.users_subscribed.find(id => id === user.id)?true:false);
        subscribed = event ? false : true
    }
    
    return {
      subscribed:subscribed
    }
  }
);

/********************************************* ARTISTS ************************* */

export const getArtistsState = createFeatureSelector<fromArtists.State>(fromArtists.artistsFeatureKey);

//seleziona la lista degli eventi
export const getArtists = createSelector(
  getArtistsState,
  fromArtists.getArtists
);

export const getArtistCategories = createSelector(
  getArtistsState,
  fromArtists.getArtistCategories
);

export const getFilterArtists = createSelector(
  getArtistsState,
  fromArtists.getFilterArtists
);

export const showMoreArtists = createSelector(
  getArtistsState,
  fromArtists.showMoreArtists
);

export const dataPageArtists = createSelector(
  getArtistsState,
  fromArtists.dataPageArtists
);

export const isPageArtistsLoaded = createSelector(
  getArtistsState,
  fromArtists.isPageArtistsLoaded
);



/********************************************* GALLERY ************************************/

export const getOperasState = createFeatureSelector<fromOperas.State>(fromOperas.operasFeatureKey);

//seleziona la lista degli eventi
export const getOperas = createSelector(
  getOperasState,
  fromOperas.getOperas
);

export const getOperasCategories = createSelector(
  getOperasState,
  fromOperas.getOperasCategories
);

export const getFilterOperas = createSelector(
  getOperasState,
  fromOperas.getFilterOperas
);

export const showMoreOperas = createSelector(
  getOperasState,
  fromOperas.showMoreOperas
);

/********************************************* JOURNALS ************************************/

export const getJournalsState = createFeatureSelector<fromJournals.State>(fromJournals.journalFeatureKey);

export const getJournals = createSelector(
  getJournalsState,
  fromJournals.getJournals
);

export const getDataPageJournal = createSelector(
  getJournalsState,
  fromJournals.dataPageJournal
);

