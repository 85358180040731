import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from './../../services/core/toast.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit {

  constructor(public toastService:ToastService) {

   }

  ngOnInit(): void {
  }

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

  remove(toast) {
    this.toastService.remove(toast);
  }
}
