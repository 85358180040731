import { Category } from "app/shared/models/category.model";
import { AvatarDataModel, DataModel, PageData } from "app/shared/models/common.model";
import { plainToClass } from "class-transformer";
import { FilterPaged } from "./common.entity";
import { EventArtist } from "./events.entity";
import { Opera } from "./operas.entity";


export interface FilterArtists extends FilterPaged {
    search:string;
    categories:Category[];
}

export class ArtistPageData extends PageData<Artist> {}

export class Artist extends DataModel {
  name: string;
  //category: Category;
  short_description: string;
  fb_page: string;
  instagram_page:string;
  pinterest_page:string;
  tiktok_page:string;
  youtube_url:string;
  site_url: string;
  email: string;
  //phone:string;
  avatar: ArtistAvatarDataModel;
  operas: Opera[];
  categories: Category[];
  userId: number;
  events: EventArtist[];

  public static fromDTOs(dtos: any[]) {
    let artists: Artist[] = [];
    for (let entity of dtos) {
      artists.push(Artist.fromDTO(entity));
    }
    return artists;
  }

  /*public static fromPaginatedDTOs(dto: any): ArtistPageInfo {
    const artistPageInfo: ArtistPageInfo = plainToClass(ArtistPageInfo, dto);
    return artistPageInfo;
  }*/

  public static fromDTO(dto: any) {
    let artist: Artist;
    if (dto[0]) {
      artist = plainToClass(Artist, dto[0]);
    } else {
      artist = plainToClass(Artist, dto);
    }

    artist.events = EventArtist.fromDTOs(artist.events);

    // if(dto['id']) {
    //   artist.id = dto['id'];
    // }

    //this.copyMatchingValues(artist,dto);
    //this.copyMatchingValues(artist.avatar,dto.avatar);

    /*sensor.id = dto.id;
    sensor.class = dto.classe;
    sensor.code = dto.code;
    sensor.description = dto.description;
    sensor.name = dto.name;
    sensor.latitude = dto.sensorGeoLat;
    sensor.longitude = dto.sensorGeoLong;
    sensor.sensorStatus = dto.sensorStatus;
    sensor.sensorType = dto.sensorTypeId;
    sensor.accessRight = dto.accessRight;
    sensor.observedProperties = ObservedProperty.fromDTOs(dto.observedProperties);
    sensor.dataStreams = dto.datastreams;
    sensor.ownerCustomer = Customer.fromDTO(dto.ownerCustomer);*/
    return artist;
  }

  //saving data to db
  public static toDTO(elem: Artist) {
    const dto: any = {};
    /*dto.id = elem.id;
    dto.classe = elem.class;
    dto.code = elem.code;
    dto.description = elem.description;
    dto.name = elem.name;
    dto.sensorGeoLat = elem.latitude;
    dto.sensorGeoLong = elem.longitude;
    dto.sensorStatus = elem.sensorStatus;
    dto.sensorTypeId = elem.sensorType;
    dto.accessRight = elem.accessRight;
    dto.observedProperties = ObservedProperty.toDTOs(elem.observedProperties);
    dto.datastreams = elem.dataStreams;
    dto.ownerCustomer = Customer.toDTO(elem.ownerCustomer);*/
    return dto;
  }
}

export class ArtistAvatarDataModel extends AvatarDataModel {}


export interface WpArtistPageData {
  title : string;
  
}



// export interface DataModel {

// }

// export interface AvatarDataModel {

// }

// export interface Category {

// }

// export interface ArtistAvatarDataModel extends AvatarDataModel {

// }

// export interface Opera {

// }

// export interface EventArtist {

// }


// export interface Artist extends DataModel {
//   name: string;
//   category: Category;
//   short_description: string;
//   fb_page: string;
//   instagram_page:string;
//   pinterest_page:string;
//   site_url: string;
//   email: string;
//   //phone:string;
//   avatar: ArtistAvatarDataModel;
//   operas: Opera[];
//   categories: Category[];
//   userId: number;
//   events: EventArtist[];
// }
