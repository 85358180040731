import { Action, createReducer, on } from "@ngrx/store";
import { Category } from "app/shared/models/category.model";
import { PageData } from "app/shared/models/common.model";
import * as artistsActions from "../actions";
import { Artist, FilterArtists, WpArtistPageData } from "../entity/artist.entity";
import { ArtistPageData } from './../entity/artist.entity';
import { EsitoMessage } from "./../entity/contacts.entity";

export const artistsFeatureKey = "artist";

export interface State {
  filter: FilterArtists;
  pageData:ArtistPageData;
  isLoadingArtists: boolean;
  artistsLoaded: boolean;
  esito: EsitoMessage;
  categories: Category[];
  isLoadingCategories: boolean;
  categoriesLoaded: boolean;
  dataPage:WpArtistPageData;
}

export const initialState: State = {
  filter: {
    search:'',
    categories:[],
    pageNumber:1,
    pageSize:PageData.PAGE_SIZE
  },
  pageData: undefined,
  categories: [],
  isLoadingArtists: false,
  artistsLoaded: false,
  esito: undefined,
  isLoadingCategories: false,
  categoriesLoaded: false,
  dataPage:undefined
};

//common function for load a reducer
export function reducer(state: State | undefined, action: Action): any {
  return artistsReducer(state, action);
}

export const artistsReducer = createReducer(
  initialState,
  on(artistsActions.findArtists, (state, { filter }) => ({
    ...state,
    filter: filter,
    isLoadingArtists: true,
    artistsLoaded: false,
  })),
  on(artistsActions.findArtistsSuccess, (state, { pageData }) => ({
    ...state,
    pageData: pageData,
    isLoadingArtists: false,
    artistsLoaded: true,
  })),
  on(artistsActions.findArtistsFailure, (state, { esito }) => ({
    ...state,
    //pageData: undefined,
    esito: esito,
    isLoadingArtists: false,
    artistsLoaded: false,
  })),
  on(artistsActions.findCategories, (state) => ({
    ...state,
    isLoadingCategories: true,
    categoriesLoaded: false,
  })),
  on(artistsActions.findCategoriesSuccess, (state, { categories }) => ({
    ...state,
    categories: categories,
    isLoadingCategories: false,
    categoriesLoaded: true,
  })),
  on(artistsActions.findCategoriesFailure, (state, { esito }) => ({
    ...state,
    categories: undefined,
    isLoadingCategories: false,
    categoriesLoaded: false,
  })),
  on(artistsActions.viewMoreArtists, (state, {filter}) => ({
    ...state,
    filter:filter,
    isLoadingArtists: true,
    artistsLoaded: false,
  })),
  on(artistsActions.viewMoreArtistsSuccess, (state, { pageData }) => ({
    ...state,
    pageData:{...state.pageData,
              data: state.pageData.data.concat(pageData.data),
              pageCount: pageData.pageCount,
              pageNumber: pageData.pageNumber
             },
    isLoadingArtists: false,
    artistsLoaded: true,
  })),
  on(artistsActions.viewMoreArtistsFailure, (state, { esito }) => ({
    ...state,//rimangono gli artisti già visualizzati
    esito: esito,
    isLoadingArtists: false,
    artistsLoaded: false,
  })),
  on(artistsActions.loadArtistPageDataSuccess, (state, { data }) => ({
    ...state,//rimangono gli artisti già visualizzati
    dataPage : data
  })),
  on(artistsActions.loadArtistPageDataFailure, (state, { data }) => ({
    ...state,//rimangono gli artisti già visualizzati
    dataPage : data,
  })),
);


export const getArtists = (state: State) => {

  if(state.pageData) {
    return Artist.fromDTOs(state.pageData.data)
  }
  else {
    return [];
  }
  ;
};


export const getArtistCategories = (state: State) => {
    return state.categories;
  };

export const getFilterArtists = (state: State) => {
    return state.filter;
};

export const showMoreArtists = (state: State) => {

  if(state.pageData) {
    return state.pageData.pageNumber < state.pageData.pageCount;
  }
}

export const dataPageArtists = (state: State) => {

  if(state.dataPage) {
    return state.dataPage;
  }
}

export const isPageArtistsLoaded = (state: State) => {

    return state.dataPage && state.artistsLoaded && state.categoriesLoaded;// && state.pageData.data;
   
}


 
