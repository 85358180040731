import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { WpArtistPageData } from "app/+state/entity/artist.entity";
import { WpJournalPageData } from 'app/+state/entity/journal.entity';
import { forkJoin, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { WpAboutUsPageData } from "../models/about-us.model";
import { WpHomePageData } from "../models/home.model";
import { environment } from './../../../environments/environment';
import { CrudService } from "./core/crud.service";
import { ErrorService } from "./core/error.service";
import { LoaderService } from "./core/loader.service";

@Injectable({
  providedIn: 'root'
})
export class WpService extends CrudService<any> {

  static SLUG_TITLE_HOME_PAGE:string = "title-home-page";
  static SLUG_TITLE_ABOUT_US:string = "about-us";
  static SLUG_ARTIST_PAGE:string = "dati-artists-page";
  static SLUG_JOURNAL_PAGE:string = "dati-journal-page";
  static SLUG_JOURNAL_IMAGE:string = "el_sil";
  

  
  

  constructor(public httpClient: HttpClient,
    public errorService: ErrorService,
    public loaderService: LoaderService) {
    super(httpClient, `${environment.endpoint}`, '/wp/v2', errorService, loaderService);
  }

  public getHomePageWpDataPage():Observable<WpHomePageData> {
    return this.getPageBySlug(WpService.SLUG_TITLE_HOME_PAGE)
    .pipe(
      map(dataPage => {
        
        const wpData = dataPage[0].content.rendered;

        var parser = new DOMParser();
	      var doc:Document = parser.parseFromString(wpData, 'text/html');
	      
        const data = <WpHomePageData>{
          title:environment.siteTitle,
          homeTitle : doc.getElementById("homeTitle").innerHTML,
          fondazione : doc.getElementById("fondazione").innerHTML,
          since : doc.getElementById("since") ? doc.getElementById("since").innerHTML : "",
          association: doc.getElementById("association") ? doc.getElementById("association").innerHTML : "association",
          founder: doc.getElementById("founder") ? doc.getElementById("founder").innerHTML : "founder",
          canonicalSiteUrl : environment.siteDomain,
          meta : doc.getElementById("meta") ? doc.getElementById("meta").innerHTML : "aggiungi meta tag",
          description : doc.getElementById("description") ? doc.getElementById("description").innerHTML : "Accademia europea delle arti",
        }

        return data;
      })
    );
  }

  
  public getAboutUsWpDataPage():Observable<WpAboutUsPageData> {
    return this.getPageBySlug(WpService.SLUG_TITLE_ABOUT_US).pipe(
      map(dataPage => {
        const wpData = dataPage[0].content.rendered;
        return wpData;
      })
    );
  }


  public getArtistsPageWpDataPage():Observable<WpArtistPageData> {
    return this.getPageBySlug(WpService.SLUG_ARTIST_PAGE)
    .pipe(
      map(dataPage => {
        
        const wpData = dataPage[0].content.rendered;
        var parser = new DOMParser();
	      var doc:Document = parser.parseFromString(wpData, 'text/html');
	      
        const data = <WpArtistPageData>{
          title : doc.getElementById("title").innerHTML,
        }
        return data;
      })
    );
  }

  public getJournalPageWpDataPage():Observable<WpJournalPageData> {


    const dataPage$ = this.getPageBySlug(WpService.SLUG_JOURNAL_PAGE);
    const imagePage$ = this.getMediaBySlug(WpService.SLUG_JOURNAL_IMAGE);


    return forkJoin({dataPage : dataPage$,imagePage :imagePage$})
    .pipe(
      map(data => {
        const wpData = data.dataPage[0].content.rendered;
        var parser = new DOMParser();
	      var doc:Document = parser.parseFromString(wpData, 'text/html');
	      
        const _data = <WpJournalPageData>{
          title : doc.getElementById("title").innerHTML,
          elSilImageUrl : data.imagePage[0],
        }
        return _data;
      })
    );
  }



  //recupera tut ele pagina paramtrizzate con lo slug
  private getPageBySlug(slug:string):Observable<ArrayBuffer> {
    let params = new HttpParams();
    params = params.append('slug', slug);

    return this.httpClient
    .get(`${this.baseUrl}${this.endpoint}/pages`, {responseType:"json",params})
    .pipe(
      map((data: any) => data),
      catchError((err) => {
        throw err;
      }),
      //finalize(() => this.loaderService.hide())
      );


  }

  private getMediaBySlug(slug:string):Observable<ArrayBuffer> {
    let params = new HttpParams();
    params = params.append('slug', slug);

    return this.httpClient
    .get(`${this.baseUrl}${this.endpoint}/media`, {responseType:"json",params})
    .pipe(
      map((data: any) => data),
      catchError((err) => {
        throw err;
      }),
      //finalize(() => this.loaderService.hide())
      );


  }
}
