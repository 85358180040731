


export interface UserSignUp {
  email?: string;
  password?: string;
  firstName?:string;
  lastName?:string;
  //username?:string;
}

export interface ResetPwd {
  email?: string;
}

export interface Credentials {
  email?: string;
  password?: string;
}


export interface User {
  email?: string;
  nicename?: string;
  firstName?:string;
  lastName?:string;
  //token:string;
  //registered?:any;
  tokenClaimObj?:TokenClaimObj;//dedotte dal token non lasciat ein chiaro nello storage

  //usate per fb
  accessToken:string;
  photoUrl:string;
  provider:string;
  id?: number;
  fbId?: string;
  name?: string;
}


  


//decodifica del token 
export interface TokenClaimObj {
  token:string;
  iss:string;
  iat:number;
  nbf:number;
  userId:number;
  email:string,
  registered:string;
  roles:string[];

  //recuperare il token dalla responsee assegnarlo all'utente 
// displayName:'Alessandro Fiori'
// email:'afiori79@gmail.com'
// firstName:'Alessandro'
// id:14 utente
// lastName:'Fiori'
// nicename:'alessandro-fiori'
// token:'eyJ0eXAiOiJKV1
}



export class UserDto{

  public static fromFbDto(data:any):User {

    if(data) {
      //return JSON.parse(JSON.stringify(data));

      const user:User = {
         accessToken : data.authToken,
         email : data.email,
         firstName : data.firstName,
         id : data.id,
         lastName:data.lastName,
         name:data.name,
         photoUrl:data.photoUrl,
         provider:data.provider
       }
       return user;
    }
  }  



  public static toDto(data:any):User {

    if(data) {
      //return JSON.parse(JSON.stringify(data));

      const user:User = {
         accessToken : '', //nessun access token semi loggo con worpress
         email : data.email,
         firstName : data.nicename,
         id : data.id,
         lastName:data.lastname,
         name:data.name,
         photoUrl:data.photoUrl,
         provider:data.provider
       }
       return user;
    }
  }  

//   public static toJSON(data?: any) {

//     data = typeof data === 'object' ? data : {};
//     // if (Array.isArray(this.accounts)) {
//     //     data["accounts"] = [];
//     //     for (let item of this.accounts)
//     //         data["accounts"].push(item.toJSON());
//     // }
//     return data; 
// }
}






