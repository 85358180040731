import { Location } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuOption } from '../models/menu.model';
import { RoutingDataType } from '../routing-action-type';








@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    public sidebarVisible: boolean;

    private iconPath = "../../../../assets/images/";

    constructor(private router:Router,public location: Location, private element : ElementRef) {
        this.sidebarVisible = false;
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        // console.log(toggleButton, 'toggle');

        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    
    /*isHome() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
        if( titlee === '/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
        if( titlee === '/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }*/

    routeMatches(regex, url = null):boolean {
      const currentUrl = url || this.router.url;
      return currentUrl.toString().match(regex);
    }
  
    onClick(url = null, urlExternal = null, index = null) {
  
      console.log(url);
      if (url) {
        this.router.navigateByUrl(url);
      }
  
      if (urlExternal) {
        window.open(urlExternal, "_blank");
      }
    }



    


    public menuOptions: Array<MenuOption> = [
        // {
        //   icon: this.iconPath + "icon-home.svg",
        //   label: "navmenu.home",
        //   match: /$g/,
        //   navigateTo: "/",
        // },
        {
          icon: this.iconPath + "icon-artisti.svg",
          label: "Video",//navmenu.artists",
          match: /(\/video\/|\/painters\/|\/sculptors\/|\/poets\/|\/musiclyricsdance\/|\/artcriticals\/)/g,
          navigateTo: '/'.concat(RoutingDataType.video),
        },
        {
          icon: this.iconPath + "gallery.svg",
          label: "Tour",//"navmenu.gallery",
          match: /\/tour\//g,
          navigateTo: '/'.concat(RoutingDataType.tour),
          roles: []
        },
        {
          icon: this.iconPath + "icon-about-us.svg",
          label: "About Us",//"navmenu.aboutus",
          match: /(\/aboutUs\/)/g,
          navigateTo: '/'.concat(RoutingDataType.aboutUs),
          roles: []
          /*subMenuOptions: [
            {
              label: "navmenu.history",
              match: /$g/,
              navigateTo: '/'.concat(RoutingDataType.history),
              roles: []
            },
            {
              label: "navmenu.eubulletin",
              match: /$g/,
              navigateTo: '/'.concat(RoutingDataType.eubulletin),
              roles: []
            },
            {
              label: "navmenu.statuto",
              match: /$g/,
              navigateTo: '/'.concat(RoutingDataType.statuto),
              roles: []
            },
            {
              label: "navmenu.sponsor",
              match: /$g/,
              navigateTo: '/'.concat(RoutingDataType.sponsor),
              roles: []
            },
          ],*/
        },
        {
          icon: this.iconPath + "icon-contacts.svg",
          label: "Contacts",//"navmenu.contacts",
          match: /$g/,
          navigateTo: '/'.concat(RoutingDataType.contacts),
          roles: []
        },
      ];




}
