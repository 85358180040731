import { Injectable } from '@angular/core';
import { User } from 'app/+state/entity';
import * as storage from 'app/+state/state/storage';
import jwt_decode from 'jwt-decode';
import { TokenClaimObj } from './../../../+state/entity/user.entity';

@Injectable({
  providedIn: 'root'
})
export class JwtTokenService {

    private jwtToken: string;
    private decodedToken: { [key: string]: any };

    constructor() {
    }

    public decodeJwtForUser(token:any):TokenClaimObj {
      const decodedToken:any = jwt_decode(token);

      return <TokenClaimObj>{
        "token":token,
        "iss":decodedToken.iss,
        "iat":decodedToken.iat,
        "nbf":decodedToken.nbf,
        "userId":decodedToken.user.ID,
        "email":decodedToken.user.email,
        "registered":decodedToken.user.registered,
        "roles":decodedToken.user.roles,
      }

      // decodedToken.exp;
      // decodedToken.iat;
      // decodedToken.iss;
      // decodedToken.nbf;

      // {
      //   "iss": "https://europeanaccademyofarts.local",
      //   "iat": 1612055878,
      //   "nbf": 1612055878,
      //   "exp": 1612660678,
      //   "data": {
      //     "user": {
      //       "id": 14
      //     }
      //   },
      //   "user": {
      //     "ID": 14,
      //     "email": "afiori79@gmail.com",
      //     "registered": "2021-01-27 18:08:08",
      //     "nice_name": "alessandro-fiori",
      //     "roles": [
      //       "subscriber"
      //     ]
      //   }
      // }




      

    }


    public updateToken(token:string) {
      const user:User = storage.getItem('user').user;

      if(user && user.tokenClaimObj) {
        user.tokenClaimObj.token = token;
      }
    }

    // setToken(token: string) {
    //   if (token) {
    //     this.jwtToken = token;
    //   }
    // }

    private decodeToken() {
      const user:User = storage.getItem('user').user;

      if(user && user.tokenClaimObj) {
        this.jwtToken = user.tokenClaimObj.token;
        //return user.tokenClaimObj.token;
      }
      else {
        this.jwtToken = null;
      }
      // this.jwtToken = user.tokenClaimObj.token;
      // if (this.jwtToken) {
      //   this.decodedToken = jwt_decode(this.jwtToken);
      // }
    }

    getJWTToken():string {
      this.decodeToken();
      return this.jwtToken;
    }

    // getDecodeToken() {
    //   return jwt_decode(this.jwtToken);
    // }

    // getUser():any  {
    //   this.decodeToken();

    //   if(this.decodedToken.user) {
    //     let result = this.decodedToken.user;
    //     result.token = this.jwtToken;
    //     return result;
    //   }
    //   else {
    //     return null;
    //   }
    // }

    // getEmailId() {
    //   this.decodeToken();
    //   return this.decodedToken ? this.decodedToken.email : null;
    // }

    private getExpiryTime() {
      this.decodeToken();
      return this.decodedToken ? this.decodedToken.exp : null;
    }

    isTokenExpired(): boolean {

      let expiry:number = this.getExpiryTime();  //(JSON.parse(atob(token.split('.')[1]))).exp;
      return (Math.floor((new Date).getTime() / 1000)) >= expiry; 
     




      // const expiryTime: number = this.getExpiryTime();
      // if (expiryTime) {
      //   return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
      // } else {
      //   return false;
      // }
      //wp-json/jwt-auth/v1/token/validate

       // import {JwtHelperService} from '@auth0/angular-jwt';
        // .
        // .
        // constructor(private jwtHelper: JwtHelperService) { }
        
        // ngOnInit() {
        //   if (this.jwtHelper.isTokenExpired(token)) {
        //     // token expired 
        //   } else {
        //     // token valid
        //   }
        // }




    }
}
