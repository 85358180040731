import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotpwdComponent } from './login/pages/forgotpwd/forgotpwd.component';
import { SubscribeComponent } from './login/pages/subscribe/subscribe.component';
import { LoginDialogComponent } from './shared/components/login-dialog/login-dialog.component';
import { RoutingDataType } from './shared/routing-action-type';

const routes: Routes = [
  { path: '', redirectTo: RoutingDataType.home, pathMatch: 'full' },
  { path: RoutingDataType.home,
    //component:HomeComponent,
    loadChildren: () => import(`./home/home.module`).then(m => m.HomeModule)
    //canActivate: [AuthGuard]
  },
  { path: RoutingDataType.login,
    component:LoginDialogComponent,
    //canActivate: [AuthGuard]
  },
  { path: RoutingDataType.subscribe,
    component:SubscribeComponent,
    //canActivate: [AuthGuard]
  },
  { path: RoutingDataType.resetPwd,
    component:ForgotpwdComponent,
    //canActivate: [AuthGuard]
  },
 
  {
    path: RoutingDataType.video,
    //loadChildren: () => ArtistsModule,import(`./admin/admin.module`).then(m => m.AdminModule)
    loadChildren: () => import(`./artists/artists.module`).then(m => m.ArtistsModule)
    //component:ArtistsShowComponent,
    //canActivate: [RoleGuard],
    //data: { role: [RoutingAppRoles.sceneAdmin, RoutingAppRoles.customerAdmin, RoutingAppRoles.customerUser] }
  },
  {
    path: RoutingDataType.aboutUs,
    loadChildren: () => import(`./about-us/about-us.module`).then(m => m.AboutUsModule)
  },
  {
    path: RoutingDataType.contacts,
    loadChildren: () => import(`./contacts/contacts.module`).then(m => m.ContactsModule)
  },
  {
    path: RoutingDataType.journal,
    loadChildren: () => import(`./journal/journal.module`).then(m => m.JournalModule)
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

