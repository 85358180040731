import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Artist } from "app/+state/entity/artist.entity";
import { FilterOperas, Opera } from "app/+state/entity/operas.entity";
import { BeResponse } from "app/+state/entity/response.entyt";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CrudService } from "../core/crud.service";
import { ErrorService } from "../core/error.service";
import { LoaderService } from "../core/loader.service";



@Injectable({
    providedIn: 'root'
  })
export class GalleryService extends CrudService<Opera> {

  //con ACF /wp-json/acf/v3/artists

  //private postfix_endp:string = 'acf/v3/operas-slug'

  //private _search = new Subject<ArtistPageInfo>();
  //public search$;// = this._search.asObservable();


  constructor(public httpClient: HttpClient,
    public errorService: ErrorService,
    public loaderService: LoaderService) {
    super(httpClient, `${environment.endpoint}`, '/gallery/v1', errorService, loaderService);
    //this.search$ = this._search.asObservable();
  }

  public searchOperas(filter:FilterOperas):Observable<BeResponse> {
    //const  params = new  HttpParams();//.set('_page', page).set('_limit', this.maxDataForPage).set('_search',search);
    //const  params = new  HttpParams({fromString:  '_page=1&_limit=1'});

    // let params: HttpParams = new HttpParams();
    // params.set('filter', JSON.stringify(filter));

    console.log(`${this.baseUrl}${this.endpoint}/findOperas`);

    return this.httpClient
      .post(`${this.baseUrl}${this.endpoint}/findOperas`, filter,this.httpOptions)
      .pipe(
        map((data: BeResponse) => data),
        catchError((err) => {
          this.error(err);
          throw err;
        }),
        //finalize(() => this.loaderService.hide())
        );
  }





  /*public loadOperaList(search:string,page:string = "-1"): Observable<Opera[]> {
    //this.loaderService.show();

    //compose pageParams
    const  params = new  HttpParams().set('_page', page).set('_limit', this.maxDataForPage).set('_search',search);
    //const  params = new  HttpParams({fromString:  '_page=1&_limit=1'});
    return this.httpClient
      .get(`${this.baseUrl}${this.endpoint}/findOperas`, {responseType:"json",params})
      .pipe(
        map((data: any) => Opera.fromDTOs(data)),
        catchError((err) => {
          this.error(err);
          throw err;
        }),
        //finalize(() => this.loaderService.hide())
        );

  }*/
  //ricerca le opere per ogni artista
  public loadOperasByArtist(artist:Artist):Observable<Opera[]> {
    //this.loaderService.show();

    // const  params = new  HttpParams({
    //   fromString:  '_page=1&_limit=1&id='+40,
    // });
    const  params = new  HttpParams();
    return this.httpClient
      .get(`${this.baseUrl}/gallery/v1/findOperasByArtist/${artist.id}`, {responseType:"json",params})
      .pipe(
        map((data: any) => Opera.fromPaginatedDTOs(data)),
        catchError((err) => {
          this.error(err);
          throw err;
        }),
        //finalize(() => this.loaderService.hide())
        );
  }

  public loadOperasById(operaId:string):Observable<Opera> {
    //this.loaderService.show();

    // const  params = new  HttpParams({
    //   fromString:  '_page=1&_limit=1&id='+40,
    // });
    const  params = new  HttpParams();
    return this.httpClient
      .get(`${this.baseUrl}${this.endpoint}/getOperaById/${operaId}`, {responseType:"json",params})
      .pipe(
        map((data: any) => Opera.fromDTO(data)),
        catchError((err) => {
          this.error(err);
          throw err;
        }),
        //finalize(() => this.loaderService.hide())
        );
  }


    
}