import { createAction, props } from "@ngrx/store";
import { Contact, EsitoMessage } from "../entity";

export const SEND_CONTACT = '[Contacts Page] Send Contact';
export const SEND_CONTACT_SUCCESS = '[Contacts Page] Send Contact Success';
export const SEND_CONTACT_FAILURE = '[Contacts Page] Send Contact Failure';

export const sendContact = createAction(
    SEND_CONTACT,
    props<{contact: Contact}>()
  );


export const sendContactSuccess = createAction(
SEND_CONTACT_SUCCESS,
props<{message:EsitoMessage}>()
)
  
export const sendContactFailure = createAction(
SEND_CONTACT_FAILURE,
props<{message: EsitoMessage}>()
)