import { Action, createReducer, on } from '@ngrx/store';
import * as contactActions from '../actions';
import { Contact, EsitoMessage } from '../entity';

export const contactsFeatureKey = 'contacts';

export interface State {
  contact: Contact;
  message: EsitoMessage;
  isSending :boolean;
  isSended :boolean;
}

export const initialState: State = {
  isSending: false,
  isSended:false,
  contact: null,
  message: undefined
};

//common function for load a reducer
export function reducer(state: State | undefined, action: Action): any {
  return contactReducer(state, action);
}

export const contactReducer = createReducer(
  initialState,
  on(contactActions.sendContact, (state, { contact }) => ({
    ...state,
    isSending : true,
    contact: contact
  })),
  on(contactActions.sendContactSuccess, (state, {message}) => ({
    ...state,
    isSending : false,
    message:message
  })),
  on(contactActions.sendContactFailure, (state, { message }) => ({
    ...state,
    isSending : false,
    message:message,
  }))
);


export const getContact = (state: State) => {
    return {
      contact: state.contact
    }
  };

  export const contactSended = (state: State) => {
    return {
      esito: state.message
    }
  };

