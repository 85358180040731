import { BeResponse } from "../entity/response.entyt";



export class CommonEffects {

    protected checkResponse(response:BeResponse):boolean {
        
        if(response.success) {
            return true;
        }

        throw new Error(response.message);
        
    }

}