import { Action, createReducer, on } from '@ngrx/store';
import * as userActions from '../actions';
import { User } from '../entity';
import * as storage from '../state/storage';


// const CONTRIBUTOR = "contributor";     
// const SUBSCRIBER = "subscriber"; 
// const EDITOR = "editor"; 
// const AUTHOR = "author"; 
// const ADMINISTRATOR = "administrator"; 

enum Roles {
  CONTRIBUTOR = "contributor",
  SUBSCRIBER = "subscriber",
  EDITOR = "editor",
  AUTHOR = "author",
  ADMINISTRATOR = "administrator"
}


export interface State {
  user: User;
  result: any;//risultato della response
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
  // isLoadingSignUpSuccess: boolean;
  // isLoadingSignUpFailure: boolean;
  // isLoadingResetPwdSuccess: boolean;
  // isLoadingResetPwdFailure: boolean;
  errorMessage:string;
}

export const initialState: State = {
  user: storage.getItem('user').user,
  result: undefined,//valore della response
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  // isLoadingSignUpSuccess: false,
  // isLoadingSignUpFailure: false,
  // isLoadingResetPwdSuccess: false,
  // isLoadingResetPwdFailure: false,
  errorMessage: "",
};



const loginReducer = createReducer(
  initialState,
  on(userActions.login, (state, {credentials}) => ({
    ...state,
    isLoading: true})),
  on(userActions.loginSuccess, (state, {user,result}) => ({
    ...state,
    user: user, //decodificare il token utente
    result, 
    isLoading: false,
    isLoadingSuccess: true})),
  on(userActions.loginFailure, (state, {message}) => ({
      ...state,
      isLoadingFailure: true,
      errorMessage: message
    })),
  on(userActions.goToSignUp, (state) => ({
      ...state,
      isLoading: false})),
  on(userActions.signUp, (state, {userSignUp}) => ({
    ...state,
    //user, 
    isLoading: true})),
  on(userActions.signUpSuccess, (state) => ({
    ...state,
  //   user: state.user, 
     //result, 
     isLoading: false, 
     isLoadingSignUpSuccess: true})),
  on(userActions.signUpFailure, (state,{message}) => ({
      ...state,
      //user : null,
      isLoading: false,
      isLoadingSignUpSuccess: false,
      errorMessage : message
      })),
  on(userActions.resetPwd, (state, {resetPwd}) => ({
    ...state,
    //user, 
    isLoading: true})),
  on(userActions.resetPwdSuccess, (state, result) => ({
    ...state,
    result, 
    isLoading: false, 
    isLoadingSignUpSuccess: true})),
  //facebbok
  on(userActions.facebookLogin, (state,) => ({
    ...state,
    isLoading: true,
    isLoadingSignUpSuccess: false
    })),
  on(userActions.facebookLoginSuccess, (state,{user}) => ({
    ...state,
    user: user, 
    result: user, 
    isLoading: false, 
    isLoadingSignUpSuccess: true
  })),
  // on(userActions.facebookJWTLoginSuccess, (state,{user,result}) => ({
  //   ...state,
  //   user: user, 
  //   result: result, 
  //   isLoading: false, 
  //   isLoadingSignUpSuccess: true
  // })),
  on(userActions.facebookLoginFailure, (state,{message}) => ({
    ...state,
    user : null,
    isLoading: false,
    isLoadingSignUpSuccess: false,
    errorMessage : message
    })),
   on(userActions.logout, (state) => ({
       ...state,
       user : null,
       isLoading: false,
       isLoadingSignUpSuccess: false,
       errorMessage : undefined
       })),
);

export function reducer(state: State | undefined, action: Action): any {
  return loginReducer(state, action);
}

export const getLoggedInUser = (state: State) => {
  return {
    user: state.user,
    isLoadingSuccess: state.isLoadingSuccess
  }
};

export const userLogin = (state: State) => {
  return {
    user: state.user,
    result: state.result,
    isLoading: state.isLoading,
    isLoadingSuccess: state.isLoadingSuccess
  }
};

export const userFailure = (state: State) => {
  return {
    errorMessage: state.errorMessage,
    isLoadingFailure : state.isLoadingFailure
  }
};

export const signUpSuccess = (state: State) => {
  return {
    isSignUpSuccess : state.isLoadingSuccess
  }
};

export const signUpFailure = (state: State) => {
  return {
    errorMessage: state.errorMessage,
    isSignUpFailure : state.isLoadingFailure
  }
};

export const resetPwdFailure = (state: State) => {
  return {
    errorMessage: state.errorMessage,
    isRestPwdFailure : state.isLoadingFailure
  }
};


export const getUserId = (state: State) => {
  return {
    userId: state.user ? state.user.id : undefined
  }
};


export const userHasRoleContributor = (state: State) => {
  return state.user ? checkRoles(state.user,[Roles.CONTRIBUTOR,Roles.EDITOR,Roles.ADMINISTRATOR]) : false;
};

export const userHasRoleSubscriber = (state: State) => {
  return state.user ? state.user.tokenClaimObj.roles.includes(Roles.SUBSCRIBER) : false;
};

function checkRoles(user:User,roles:Roles[]):boolean {

  if(roles && roles.length > 0){
    let found = false;
    roles.forEach(role => {
       if(user.tokenClaimObj.roles.includes(role)){
         found = true;
         return ;
       }
    })
    return found;
  }
  else {
    return false;
  }
}


/*export const userSignup = (state: State) => {
  return {
    user: state.user,
    result: state.result,
    isLoading: state.isLoading,
    isLoadingSuccess: state.isLoadingSuccess
  }
};*/
