import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Artist, FilterArtists } from 'app/+state/entity/artist.entity';
import { Opera } from 'app/+state/entity/operas.entity';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CrudService } from '../core/crud.service';
import { ErrorService } from '../core/error.service';
import { LoaderService } from '../core/loader.service';
import { BeResponse } from './../../../+state/entity/response.entyt';

@Injectable({
  providedIn: 'root'
})
export class ArtistsService extends CrudService<Artist> {

  //con ACF /wp-json/acf/v3/artists

  //private _search = new Subject<ArtistPageInfo>();
  //public search$;// = this._search.asObservable();


  constructor(public httpClient: HttpClient,
    public errorService: ErrorService,
    public loaderService: LoaderService) {
    super(httpClient, `${environment.endpoint}`,'/artists/v1', errorService, loaderService);
    //this.search$ = this._search.asObservable();
   }


  public searchArtists(filter:FilterArtists):Observable<BeResponse> {
    //const  params = new  HttpParams();//.set('_page', page).set('_limit', this.maxDataForPage).set('_search',search);
    //const  params = new  HttpParams({fromString:  '_page=1&_limit=1'});

    // let params: HttpParams = new HttpParams();
    // params.set('filter', JSON.stringify(filter));

    console.log(`${this.baseUrl}${this.endpoint}/findArtists`);

    return this.httpClient
      .post(`${this.baseUrl}${this.endpoint}/findArtists`, filter,this.httpOptions)
      .pipe(
        map((data: BeResponse) => data),
        catchError((err) => {
          this.error(err);
          throw err;
        }),
        //finalize(() => this.loaderService.hide())
        );
  }


  // public loadArtists(): Observable<Artist[]> {
  //   this.loaderService.show();

  //   //compose pageParams
  //   //const  params = new  HttpParams({fromString:  '_page=1&_limit=1'});
  //   return this.httpClient
  //     .get(`${this.baseUrl}/acf/v3/artists-slug/`)
  //     .pipe(
  //       map((data: any) => Artist.fromDTOs(data)),
  //       catchError((err) => {
  //         this.error(err);
  //         throw err;
  //       }),
  //       finalize(() => this.loaderService.hide()));

  // }





  //carica tutti gli artisti
  public loadArtistsList(search:string = "",page:string = "-1"): Observable<Artist[]> {
    //this.loaderService.show();

    //compose pageParams
    const  params = new  HttpParams().set('_page', page).set('_limit', this.maxDataForPage).set('_search',search);
    //const  params = new  HttpParams({fromString:  '_page=1&_limit=1'});


    console.log(`${this.baseUrl}${this.endpoint}/findArtists`);

    return this.httpClient
      .get(`${this.baseUrl}${this.endpoint}/findArtists`, {responseType:"json",params})
      .pipe(
        map((data: any) => Artist.fromDTOs(data)),
        catchError((err) => {
          this.error(err);
          throw err;
        }),
        //finalize(() => this.loaderService.hide())
        );

  }

  public getArtistById(id:number): Observable<Artist> {
    //this.loaderService.show();
    return this.httpClient
      .get(`${this.baseUrl}${this.endpoint}/getArtistById/${id}`)
      .pipe(
        map((data: any) => Artist.fromDTO(data)),
        catchError((err) => {
          this.error(err);
          throw err;
        }),
        //finalize(() => this.loaderService.hide())
        );
  }


  public contactForOpera(opera:Opera):Observable<BeResponse> {

    return this.httpClient
      .post(`${this.baseUrl}${this.endpoint}/contactForArtistOpera`,opera.id,this.httpOptions)
      .pipe(
        map((data: BeResponse) => data),
        catchError((err) => {
          this.error(err);
          throw err;
        }),
        //finalize(() => this.loaderService.hide())
        );
    
  }
}



// const results:ArtistsHead[] = [
//   {
//     name: "Abate Romano",
//     category:"Scultura",
//     photoUrl: "http://accademia-europea-delle-arti.local/wp-content/uploads/2020/12/abate-romano-150x150.jpg",
//     facebookPageUrl: "http://www.google.com"
//   },
//   {
//     name: "Allemandou Marie–Hélène",
//     category:"Pittura",
//     photoUrl: "http://accademia-europea-delle-arti.local/wp-content/uploads/2020/12/abate-romano-scaled.jpg",
//     facebookPageUrl: "www.google.com"
//   },
//   {
//     name: "Balljana Carlo",
//     category:"Scultura",
//     photoUrl: "assets/images/artists/Carlo-Balljana.jpg",
//     facebookPageUrl: "www.google.com"
//   },
//   {
//     name: "Barata Manuel",
//     category:"Pittura",
//     photoUrl: "assets/images/artists/barata-manuel.jpg",
//     facebookPageUrl: "www.google.com"
//   },
//   {
//     name: "Barbon Enzo",
//     category:"Scultura",
//     photoUrl: "assets/images/artists/no-utente.jpg",
//     facebookPageUrl: "www.google.com"
//   },
//   {
//     name: "Allemandou Marie–Hélène",
//     category:"Pittura",
//     photoUrl: "assets/images/artists/Marie-Helene-Allemandou.jpg",
//     facebookPageUrl: "www.google.com"
//   },
//   {
//     name: "Allemandou Marie–Hélène",
//     category:"Pittura",
//     photoUrl: "assets/images/artists/Marie-Helene-Allemandou.jpg",
//     facebookPageUrl: "www.google.com"
//   },
//   {
//     name: "Allemandou Marie–Hélène",
//     category:"Pittura",
//     photoUrl: "assets/images/artists/Marie-Helene-Allemandou.jpg",
//     facebookPageUrl: "www.google.com"
//   },
//   {
//     name: "Allemandou Marie–Hélène",
//     category:"Pittura",
//     photoUrl: "assets/images/artists/Marie-Helene-Allemandou.jpg",
//     facebookPageUrl: "www.google.com"
//   },
//   {
//     name: "Allemandou Marie–Hélène",
//     category:"Pittura",
//     photoUrl: "assets/images/artists/Marie-Helene-Allemandou.jpg",
//     facebookPageUrl: "www.google.com"
//   },
//   {
//     name: "Barbon Enzo",
//     category:"Scultura",
//     photoUrl: "assets/images/artists/no-utente.jpg",
//     facebookPageUrl: "www.google.com"
//   },
//   {
//     name: "Barbon Enzo",
//     category:"Scultura",
//     photoUrl: "assets/images/artists/no-utente.jpg",
//     facebookPageUrl: "www.google.com"
//   },
//   {
//     name: "Abate Romano",
//     category:"Scultura",
//     photoUrl: "assets/images/artists/abate-romano.jpg",
//     facebookPageUrl: "http://www.google.com"
//   },
//   {
//     name: "Abate Romano",
//     category:"Scultura",
//     photoUrl: "assets/images/artists/abate-romano.jpg",
//     facebookPageUrl: "http://www.google.com"
//   },
//   {
//     name: "Abate Romano",
//     category:"Scultura",
//     photoUrl: "assets/images/artists/abate-romano.jpg",
//     facebookPageUrl: "http://www.google.com"
//   },
//   {
//     name: "Abate Romano",
//     category:"Scultura",
//     photoUrl: "assets/images/artists/abate-romano.jpg",
//     facebookPageUrl: "http://www.google.com"
//   },
//   {
//     name: "Barata Manuel",
//     category:"Pittura",
//     photoUrl: "assets/images/artists/barata-manuel.jpg",
//     facebookPageUrl: "www.google.com"
//   },
//   {
//     name: "Barata Manuel",
//     category:"Pittura",
//     photoUrl: "assets/images/artists/barata-manuel.jpg",
//     facebookPageUrl: "www.google.com"
//   },
//   {
//     name: "Barata Manuel",
//     category:"Pittura",
//     photoUrl: "assets/images/artists/barata-manuel.jpg",
//     facebookPageUrl: "www.google.com"
//   },
//   {
//     name: "Barata Manuel",
//     category:"Pittura",
//     photoUrl: "assets/images/artists/barata-manuel.jpg",
//     facebookPageUrl: "www.google.com"
//   },


//];
