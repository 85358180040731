import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {

  private _searchValue: Subject<string> = new Subject();



  //private searchCtrl$ = this._searchCtrl.asObservable();



  @Output()
  searchValue:EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    this._searchValue.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    ).subscribe(data => {
      console.log(data);
      this.searchValue.emit(data);
    });

  }

  ngAfterViewInit() {
  }

  onSearchCustomer(event: any) {
    //(keyup)="onSearchCustomer($event)"
    this._searchValue.next(event.target.value);
  }

  public ngOnDestroy() {

  }

}
