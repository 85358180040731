import { createAction, props } from "@ngrx/store";
import { EsitoMessage } from "../entity";
import { FilterEvents, SubscribeEvent } from "../entity/events.entity";
import { GenericEvent } from './../entity/events.entity';

export const FIND_EVENTS = '[Events Page] Find Events';
export const FIND_EVENTS_SUCCESS = '[Events Page] Find Events Failure';
export const FIND_EVENTS_FAILURE = '[Events Page] Find Events Success';
export const SUBSCRIBE_TO_EVENT = '[Events Page] Subscribe To Event';
export const SUBSCRIBE_TO_EVENT_SUCCESS = '[Events Page] Subscribe To Event Success';
export const SUBSCRIBE_TO_EVENT_FAILURE = '[Events Page] Subscribe To Event Failure';
export const UNSUBSCRIBE_TO_EVENT = '[Events Page] Unsubscribe To Event';
export const UNSUBSCRIBE_TO_EVENT_SUCCESS = '[Events Page] Unsubscribe To Event Success';
export const UNSUBSCRIBE_TO_EVENT_FAILURE = '[Events Page] Unsubscribe To Event Failure';


export const findEvents = createAction(
    FIND_EVENTS,
    props<{filter: FilterEvents}>()
  );

export const findEventsSuccess = createAction(
    FIND_EVENTS_SUCCESS,
    props<{events: GenericEvent[]}>()
);

export const findEventsFailure = createAction(
    FIND_EVENTS_FAILURE,
    props<{esito: EsitoMessage}>()
    );

export const subscribeToEvent = createAction(
    SUBSCRIBE_TO_EVENT,
    props<{eventId: SubscribeEvent}>()
  );


export const subscribeToEventSuccess = createAction(
    SUBSCRIBE_TO_EVENT_SUCCESS,
    props<{event:GenericEvent}>()
)
  
export const subscribeToEventFailure = createAction(
    SUBSCRIBE_TO_EVENT_FAILURE,
    props<{esito: EsitoMessage}>()
)

export const unsubscribeToEvent = createAction(
    UNSUBSCRIBE_TO_EVENT,
    props<{eventId: SubscribeEvent}>()
  );


export const unsubscribeToEventSuccess = createAction(
    UNSUBSCRIBE_TO_EVENT_SUCCESS,
    props<{event:GenericEvent}>()
)
  
export const unsubscribeToEventFailure = createAction(
    UNSUBSCRIBE_TO_EVENT_FAILURE,
    props<{esito: EsitoMessage}>()
)