import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Category } from 'app/shared/models/category.model';
import { WpService } from 'app/shared/services/wp.service';
import { of } from "rxjs";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { ArtistsService } from "../../shared/services/business/artists.service";
import { CategoryService } from '../../shared/services/business/category.service';
import * as artistsActions from "../actions";
import { EsitoMessage } from "../entity";
import { WpArtistPageData } from '../entity/artist.entity';
import { BeResponse } from "../entity/response.entyt";
import { CommonEffects } from "./common.effects";

@Injectable()
export class ArtistsEffects extends CommonEffects {
  constructor(
    private actions$: Actions,
    private readonly router: Router,
    private artistsService: ArtistsService,
    private categoryService:CategoryService,
    private wpService:WpService
  ) {
    super();
  }

  loadPageData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(artistsActions.loadArtistPageData),
      exhaustMap((action) => {
        return this.wpService.getArtistsPageWpDataPage().pipe(
          map((response: WpArtistPageData) => {
            if (response) {
              //se ok signup richiamo la login in auto,matico
              return artistsActions.loadArtistPageDataSuccess({
                data: response,
              });
            } else {
              const data: WpArtistPageData = <WpArtistPageData>{
                title : "Artists",
              };
              return artistsActions.loadArtistPageDataFailure({ data: data });
            }
          }),
          catchError((error: any) =>
            of(
              artistsActions.loadArtistPageDataFailure({ data: undefined })
            )
          )
        );
      })
    )
  );

  findArtists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(artistsActions.findArtists),
      exhaustMap((action) => {
        return this.artistsService.searchArtists(action.filter).pipe(
          map((response: BeResponse) => {
            if (response.success) {
              //se ok signup richiamo la login in auto,matico
              return artistsActions.findArtistsSuccess({
                pageData: response.data,
              });
            } else {
              const esito: EsitoMessage = <EsitoMessage>{
                ok: true,
                message: response.message,
                //                 code?:string;
                // data?:any;
                // message?:string;
                // statusCode?:string;
                // success?:boolean;
              };
              return artistsActions.findArtistsFailure({ esito: esito });
            }
          }),
          catchError((error: any) =>
            of(
              artistsActions.findArtistsFailure({
                esito: {
                  ok: false,
                  message: error.message,
                },
              })
            )
          )
        );
      })
    )
  );

  findCategories$ = createEffect(() =>
  this.actions$.pipe(
    ofType(artistsActions.findCategories),
    exhaustMap((action) => {
      return this.categoryService.loadArtistsCategories().pipe(
        map((response: BeResponse) => {
          if (response.success) {
            //se ok signup richiamo la login in auto,matico
            return artistsActions.findCategoriesSuccess({
              categories: Category.fromDTOs(response.data)
            });
          } else {
            const esito: EsitoMessage = <EsitoMessage>{
              ok: true,
              message: response.message,
              //                 code?:string;
              // data?:any;
              // message?:string;
              // statusCode?:string;
              // success?:boolean;
            };
            return artistsActions.findCategoriesFailure({ esito: esito });
          }
        }),
        catchError((error: any) =>
          of(
            artistsActions.findCategoriesFailure({
              esito: {
                ok: false,
                message: error.message,
              },
            })
          )
        )
      );
    })
  )
);


viewMoreArtists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(artistsActions.viewMoreArtists),
      exhaustMap((action) => {

        //prendo l'ultimo vlaore del filtro e ci applico la slice di + pageSize
        //al ritorno aggiungo tutto nello store.
        return this.artistsService.searchArtists(action.filter).pipe(
          map((response: BeResponse) => {
            if (response.success) {
              //se ok signup richiamo la login in auto,matico
              return artistsActions.viewMoreArtistsSuccess({
                pageData: response.data,//artisti da aggiunge a già quelli presenti
              });
            } else {
              const esito: EsitoMessage = <EsitoMessage>{
                ok: true,
                message: response.message,
                //                 code?:string;
                // data?:any;
                // message?:string;
                // statusCode?:string;
                // success?:boolean;
              };
              return artistsActions.viewMoreArtistsFailure({ esito: esito });
            }
          }),
          catchError((error: any) =>
            of(
              artistsActions.findArtistsFailure({
                esito: {
                  ok: false,
                  message: error.message,
                },
              })
            )
          )
        );
      })
    )
  );


}
