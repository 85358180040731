import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '../models/category.model';

@Pipe({
  name: 'categories'
})
export class CategoriesPipe implements PipeTransform {

  transform(value: Category[], ...args: unknown[]): string {

    if(value) {
      return value.map(function(category:Category){
        return category.category;
    }).join(",");
    }

    return "";
    
  }

}
