import { createAction, props } from "@ngrx/store";
import { Category } from "app/shared/models/category.model";
import { EsitoMessage } from "../entity";
import { FilterOperas, OperaPageData } from "../entity/operas.entity";

export const FIND_OPERAS = '[Operas Page] Find Operas';
export const FIND_OPERAS_SUCCESS = '[Operas Page] Find Operas Success';
export const FIND_OPERAS_FAILURE = '[Operas Page] Find Operas Failure';

export const FIND_CATEGORIES = '[Operas Page] Find Categories';
export const FIND_CATEGORIES_SUCCESS = '[Operas Page] Find Categories Failure';
export const FIND_CATEGORIES_FAILURE = '[Operas Page] Find Categories Success';

export const VIEW_MORE_OPERAS = '[Operas Page] View More Operas';
export const VIEW_MORE_OPERAS_SUCCESS = '[Operas Page] View More Operas Success';
export const VIEW_MORE_OPERAS_FAILURE = '[Operas Page] View More Operas Failure';


export const findOperas = createAction(
    FIND_OPERAS,
    props<{filter: FilterOperas}>()
  );

export const findOperasSuccess = createAction(
    FIND_OPERAS_SUCCESS,
    props<{pageData: OperaPageData}>()
);

export const findOperasFailure = createAction(
    FIND_OPERAS_FAILURE,
    props<{esito: EsitoMessage}>()
    );


export const findOperasCategories = createAction(
    FIND_CATEGORIES,
    );

export const findOperasCategoriesSuccess = createAction(
    FIND_CATEGORIES_SUCCESS,
    props<{categories: Category[]}>()
);

export const findOperasCategoriesFailure = createAction(
    FIND_CATEGORIES_FAILURE,
    props<{esito: EsitoMessage}>()
    );

export const viewMoreOperas = createAction(
    VIEW_MORE_OPERAS,
    props<{filter: FilterOperas}>()
    );

export const viewMoreOperasSuccess = createAction(
    VIEW_MORE_OPERAS_SUCCESS,
    props<{pageData: OperaPageData}>()
    );

export const viewMoreOperasFailure = createAction(
    VIEW_MORE_OPERAS_FAILURE,
    props<{esito: EsitoMessage}>()
    );