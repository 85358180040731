import { AvatarDataModel, DataModel, FileModel } from "app/shared/models/common.model";
import { plainToClass } from "class-transformer";

export interface FilterEvents {
    
}

export interface SubscribeEvent {
    id:number;
}

export class Event extends DataModel {
    date:Date;
}


export class GenericEvent extends Event {
    title_event?:string;
    event_pdf?:FileEventArtist;
    event_image?:FileImageArtist;
    out_of_date_subscribe:Date;
    users_subscribed:number[];//indica la lista degli utenti sottoscritti

    public static fromDTOs(dtos: any) {

        if(dtos && dtos.length > 0) {
            let event: GenericEvent[] = [];
            for (let entity of dtos) {
                event.push(GenericEvent.fromDTO(entity));
            }
            return event;
        }else {
            return undefined;
        }
    }

    public static fromDTO(dto: any) {
        let event: GenericEvent;
        if (dto[0]) {
            event = plainToClass(GenericEvent, dto[0]);
            //event.date = new Date(dto.date);
        } else {
            event = plainToClass(GenericEvent, dto);
            //event.date = new Date(dto.date);
        }
        return event;
    }
}

export class EventArtist extends Event {
    short_description?:string;
    pdf_data?:FileEventArtist;
    photo_event?:FileImageArtist;
    artistID:number;
    video_event_url:string;
    
    public static fromDTOs(dtos: any) {

        if(dtos) {
            let event: EventArtist[] = [];
            for (let entity of dtos) {
                event.push(EventArtist.fromDTO(entity));
            }
            return event;
        }else {
            return [];
        }
    }

    public static fromDTO(dto: any) {
        let event: EventArtist;
        if (dto[0]) {
            event = plainToClass(EventArtist, dto[0]);
        } else {
            event = plainToClass(EventArtist, dto);
        }
        return event;
    }

}

export class FileEventArtist extends FileModel {
   
}

export class FileImageArtist extends AvatarDataModel {
   
}