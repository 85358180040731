import { Injectable, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: any[] = [];

  private show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
    return true;
  }


  constructor(
    //private snackBar: MatSnackBar,// crear eil toosters with bootstrap
    private translate: TranslateService
  ) { }

  public showSuccess() {
    this.showMessage(
      this.translate.instant('common.operationSuccess'));
  }

  public showMessage(message: string) {
    this.showMessageDuration(message, 2000);
  }

  public showMessageDuration(message: string, durationTime: number) {
    //this.snackBar.open(message, this.translate.instant('common.close'), { duration: durationTime });
    this.show(message, { classname: 'bg-danger text-light', delay: durationTime });
  }
}
