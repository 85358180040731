import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import * as fromRoot from 'app/+state';
import { State } from "app/+state";
import * as loginActions from 'app/+state/actions/login.actions';
import { ResetPwd } from 'app/+state/entity/user.entity';
import { AuthService } from "app/shared/services/auth.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: 'app-forgotpwd',
  templateUrl: './forgotpwd.component.html',
  styleUrls: ['./forgotpwd.component.scss']
})
export class ForgotpwdComponent implements OnInit {
  
  public restPwdForm: FormGroup;

  restPwdFormError$:Observable<String>;

  private destroy:boolean = false;

  constructor(private formBuilder: FormBuilder,private authService:AuthService,private store: Store<State>) {

    this.restPwdFormError$ = this.store.select(fromRoot.resetPwdFailure)
    .pipe(
      map(data => {
        return data.errorMessage;
      })
    );
    
    
  }

  ngOnInit() {
    this.restPwdForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]]
    });
  }


  public resetPwd() {

      //this.socialAuthService.initState.subscribe(a => console.log(a));
      let resetPwd:ResetPwd =  {
        email: this.restPwdForm.value.email, 
       };
       this.store.dispatch(loginActions.resetPwd({ 
          resetPwd: resetPwd
        }));
  }

  
  ngOnDestroy(){
    this.destroy = true;  
  }
}


