import { createAction, props } from "@ngrx/store";
import { Category } from "app/shared/models/category.model";
import { EsitoMessage } from "../entity";
import { ArtistPageData, FilterArtists, WpArtistPageData } from "../entity/artist.entity";


export const FIND_ARTISTS = '[Artist Page] Find Artists';
export const FIND_ARTISTS_SUCCESS = '[Artist Page] Find Artists Success';
export const FIND_ARTISTS_FAILURE = '[Artist Page] Find Artists Failure';

export const FIND_CATEGORIES = '[Artist Page] Find Categories';
export const FIND_CATEGORIES_SUCCESS = '[Artist Page] Find Categories Failure';
export const FIND_CATEGORIES_FAILURE = '[Artist Page] Find Categories Success';

export const VIEW_MORE_ARTISTS = '[Artist Page] View More Artist';
export const VIEW_MORE_ARTISTS_SUCCESS = '[Artist Page] View More Artist Success';
export const VIEW_MORE_ARTISTS_FAILURE = '[Artist Page] View More Artist Failure';

export const LOAD_DATA_PAGE_ARTISTS = '[Artist Page] View Data Pege Artist';
export const LOAD_DATA_PAGE_ARTISTS_SUCCESS = '[Artist Page] View Data Pege Artist Success';
export const LOAD_DATA_PAGE_ARTISTS_FAILURE = '[Artist Page] View Data Pege Artist Failure';


export const findArtists = createAction(
    FIND_ARTISTS,
    props<{filter: FilterArtists}>()
  );

export const findArtistsSuccess = createAction(
    FIND_ARTISTS_SUCCESS,
    props<{pageData: ArtistPageData}>()
);

export const findArtistsFailure = createAction(
    FIND_ARTISTS_FAILURE,
    props<{esito: EsitoMessage}>()
    );


export const findCategories = createAction(
    FIND_CATEGORIES,
    );

export const findCategoriesSuccess = createAction(
    FIND_CATEGORIES_SUCCESS,
    props<{categories: Category[]}>()
);

export const findCategoriesFailure = createAction(
    FIND_CATEGORIES_FAILURE,
    props<{esito: EsitoMessage}>()
    );

export const viewMoreArtists = createAction(
    VIEW_MORE_ARTISTS,
    props<{filter: FilterArtists}>()
    );

export const viewMoreArtistsSuccess = createAction(
    VIEW_MORE_ARTISTS_SUCCESS,
    props<{pageData: ArtistPageData}>()
    );

export const viewMoreArtistsFailure = createAction(
    VIEW_MORE_ARTISTS_FAILURE,
    props<{esito: EsitoMessage}>()
    );

export const loadArtistPageData = createAction(
    LOAD_DATA_PAGE_ARTISTS,
    );

export const loadArtistPageDataSuccess = createAction(
    LOAD_DATA_PAGE_ARTISTS_SUCCESS,
    props<{data: WpArtistPageData}>()
    );

export const loadArtistPageDataFailure = createAction(
    LOAD_DATA_PAGE_ARTISTS_FAILURE,
    props<{data: WpArtistPageData}>()
    );
