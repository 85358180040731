import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateCalendar } from '../models/common.model';

@Pipe({
  name: 'dateToCalendar'
})
export class DateToCalendarPipe implements PipeTransform {

  constructor( private readonly translateService:TranslateService){}

  transform(date: string): DateCalendar {
    
    var dateParts = date.split("/");

    //const translate:string = this.translateService.instant('common.month.' + dateParts[1]);
    var dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]); 

    const result:DateCalendar = new DateCalendar();
    result.day = this.translateService.instant('common.day.' + dateObject.getDay());
    result.month = this.translateService.instant('common.month.' + dateParts[1]);
    result.year = dateParts[2].slice(2,4);
  
    return result;
  }

}
