import { Action, createReducer, on } from '@ngrx/store';
import * as LanguageActions from '../actions';
import { Language } from '../entity';

export const languageFeatureKey = 'language';

export interface State {
  current: Language;
}

export const initialState: State = {
  current: (navigator.language.toLowerCase().startsWith('it')
    ? 'en'
    : 'fr') as Language
};

//common function for load a reducer
export function reducer(state: State | undefined, action: Action): any {
  return i18nReducer(state, action);
}

export const i18nReducer = createReducer(
  initialState,
  on(LanguageActions.setLanguage, (state, { language }) => ({
    ...state,
    current: language
  }))
);

export const getCurrentLanguage = (state: State) =>
{
  return state.current;//[languageFeatureKey].current;
};
