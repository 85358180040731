import { DataModel, FileModel, PageData, WpMediaImageDataModel } from "app/shared/models/common.model";
import { plainToClass } from "class-transformer";
import { FilterPaged } from "./common.entity";



export interface WpJournalPageData {
  title : string;
  elSilImageUrl : WpMediaImageDataModel;
}

export interface FilterJournal extends FilterPaged {
    
}

export class JournalPageData extends PageData<Journal> {}


export class Journal extends DataModel {
    mouth:Date;
    description:string;
    periodico:FileJournal;

    public static fromDTOs(dtos: any[]) {
        let journals: Journal[];
        if(dtos && dtos.length > 0) {
          journals = [];
          for (let entity of dtos) {
            journals.push(Journal.fromDTO(entity));
          }
        }
       
        return journals;
      }

      public static fromDTO(dto: any) {
        let journal: Journal;
        if (dto[0]) {
          journal = plainToClass(Journal, dto[0]);
        } else {
          journal = plainToClass(Journal, dto);
        }
    
        return journal;
      }
}


export class FileJournal extends FileModel {   
}

export class JournalElSil extends Journal {
}

