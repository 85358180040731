import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromRoot from 'app/+state';
import { State } from 'app/+state';
import { Opera, OperaAvatarDataModel } from 'app/+state/entity/operas.entity';
import { AvatarDataModel } from 'app/shared/models/common.model';
import { ArtistsService } from 'app/shared/services/business/artists.service';
import { Lightbox } from 'ngx-lightbox';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { CommonComponent } from '../../../common/common.component';

/*
* dettaglio di ogni opera
*/

@Component({
  selector: 'item-opera',
  templateUrl: './item-opera.component.html',
  styleUrls: ['./item-opera.component.scss']
})
export class ItemOperaComponent extends CommonComponent implements OnInit,OnDestroy {

  @Input()
  opera:Opera;


  subOperas:Array<OperaAvatarDataModel>;
  subOperaSelected:OperaAvatarDataModel;

  isUserContributor$:Observable<boolean>;

  private lightBoxData: Array<any> = [];

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    center: false,
    nav: false,
    margin: 0,
    autoWidth: true,
    //stagePadding:2,
    //navContainer: "custom",
    //navText: ["", ""],
    items: 5,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 4,
      },
      740: {
        items: 5,
      },
      940: {
        items: 10,
      },
    },
  };


  constructor(private showOperaLightBox:Lightbox,public artistService:ArtistsService,private store: Store<State>,public translate: TranslateService) {
    super(translate);
    this.isUserContributor$ =  this.store.select(fromRoot.userHasRoleContributor)
      .pipe(
        takeWhile(() => this.isAlive)
      );
   }

  ngOnInit(): void {
    this.resetOpera();
  }

  private resetOpera() {
    if(this.opera) {
      this.subOperaSelected = this.opera.dft_image;

      this.subOperas = new Array();
      if(this.opera.image1) {
        this.subOperas.push(this.opera.image1);
      }
      if(this.opera.image2) {
        this.subOperas.push(this.opera.image2);
      }
      if(this.opera.image3) {
        this.subOperas.push(this.opera.image3);
      }
      if(this.opera.image4) {
        this.subOperas.push(this.opera.image4);
      }
      if(this.opera.image5) {
        this.subOperas.push(this.opera.image5);
      }

      if(this.subOperas.length > 0) {
        this.subOperas.push(this.subOperaSelected);
      }
    }
    
  }

  ngOnChanges(changes: SimpleChanges) {

      //console.log(JSON.stringify(changes.currentValue)); 
      this.resetOpera(); 
  }

  public haveMoreSubOpera() {
    return true;
    //this.subOperas && this.subOperas.length > 1;
  }

  public openOpera() {

    this.lightBoxData.pop();
    const photo = {
      src: this.opera.dft_image.sizes.large,
      caption: this.opera.dft_image.caption,
      thumb: this.opera.dft_image.sizes.thumbnail
   };

   this.lightBoxData.push(photo);
    this.showOperaLightBox.open(this.lightBoxData, 0);
  }

  public viewImageOpera(subOpera:AvatarDataModel) {
    this.subOperaSelected = subOpera;
  }

  close(): void {
    // close lightbox programmatically
    this.showOperaLightBox.close();
  }

  contactForOpera() {
    //send email with slelcted opera
    this.artistService.contactForOpera(this.opera).subscribe(
      data => console.log(data));
    
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

}
