import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { SeoService } from 'app/shared/services/core/seo.service';

@Component({
  selector: 'seo-canonical-url',
  template: '',
})
export class SeoCanonicalUrlComponent {

  @Input()
  set canonicalUrl(url: string) {
    this.seoService.setCanonicalUrl(url);
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private seoService: SeoService,
  ) {
    this.canonicalUrl = this.document.location.href.replace(/\/*\?.*/, '');
  }
}
