import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromRoot from 'app/+state';
import { State } from 'app/+state';
import * as eventActions from 'app/+state/actions/events.actions';
import { GenericEvent, SubscribeEvent } from 'app/+state/entity/events.entity';
import { Observable } from 'rxjs';
import { pluck, takeWhile } from 'rxjs/operators';
import { CommonComponent } from '../common/common.component';

@Component({
  selector: 'app-generic-event',
  templateUrl: './generic-event.component.html',
  styleUrls: ['./generic-event.component.css']
})
export class GenericEventComponent extends CommonComponent implements OnInit, OnDestroy {

  @Input()
  event:GenericEvent;

  isUserSubscribed$:Observable<boolean>; 
  isUserContributor$:Observable<boolean>;


  constructor(private store: Store<State>, public translate: TranslateService,) {
    super(translate);
    this.isUserContributor$ =  this.store.select(fromRoot.userHasRoleContributor)
    .pipe(
      takeWhile(() => this.isAlive)
    ); 
  }

  ngOnInit(): void {
    this.isUserSubscribed$ = this.store.select(fromRoot.isGenericEventSubscribedToUser,{id:this.event.id})
    .pipe(
      pluck('subscribed'));
  }


  //sottoscrizioen all'evento
  subscribe(){

    let subscribeEvent:SubscribeEvent = {
      id:this.event.id,
    }
    this.store.dispatch(eventActions.subscribeToEvent({ 
      eventId: subscribeEvent
      }));
  }

  unSubscribe(){

    let unsubscribeEvent:SubscribeEvent = {
      id:this.event.id,
    }
    this.store.dispatch(eventActions.unsubscribeToEvent({ 
      eventId: unsubscribeEvent
      }));
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    
  }
}
