import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Category } from 'app/shared/models/category.model';
import { GalleryService } from "app/shared/services/business/gallery.service";
import { of } from "rxjs";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { CategoryService } from '../../shared/services/business/category.service';
import * as operasActions from "../actions/operas.actions";
import { EsitoMessage } from "../entity";
import { BeResponse } from "../entity/response.entyt";
import { CommonEffects } from "./common.effects";

@Injectable()
export class OperasEffects extends CommonEffects {
  constructor(
    private actions$: Actions,
    private readonly router: Router,
    private galleryService: GalleryService,
    private categoryService:CategoryService
  ) {
    super();
  }

  findOperas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(operasActions.findOperas),
      exhaustMap((action) => {
        return this.galleryService.searchOperas(action.filter).pipe(
          map((response: BeResponse) => {
            if (response.success) {
              //se ok signup richiamo la login in auto,matico
              return operasActions.findOperasSuccess({
                pageData: response.data,
              });
            } else {
              const esito: EsitoMessage = <EsitoMessage>{
                ok: true,
                message: response.message,
                //                 code?:string;
                // data?:any;
                // message?:string;
                // statusCode?:string;
                // success?:boolean;
              };
              return operasActions.findOperasFailure({ esito: esito });
            }
          }),
          catchError((error: any) =>
            of(
                operasActions.findOperasFailure({
                esito: {
                  ok: false,
                  message: error.message,
                },
              })
            )
          )
        );
      })
    )
  );

  findOperasCategories$ = createEffect(() =>
  this.actions$.pipe(
    ofType(operasActions.findOperasCategories),
    exhaustMap((action) => {
      return this.categoryService.loadOperasCategories().pipe(
        map((response: BeResponse) => {
          if (response.success) {
            //se ok signup richiamo la login in auto,matico
            return operasActions.findOperasCategoriesSuccess({
              categories: Category.fromDTOs(response.data)
            });
          } else {
            const esito: EsitoMessage = <EsitoMessage>{
              ok: true,
              message: response.message,
              //                 code?:string;
              // data?:any;
              // message?:string;
              // statusCode?:string;
              // success?:boolean;
            };
            return operasActions.findOperasCategoriesFailure({ esito: esito });
          }
        }),
        catchError((error: any) =>
          of(
            operasActions.findOperasCategoriesFailure({
              esito: {
                ok: false,
                message: error.message,
              },
            })
          )
        )
      );
    })
  )
);


viewMoreOperas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(operasActions.viewMoreOperas),
      exhaustMap((action) => {

        //prendo l'ultimo valore del filtro e ci applico la slice di + pageSize
        //al ritorno aggiungo tutto nello store.
        return this.galleryService.searchOperas(action.filter).pipe(
          map((response: BeResponse) => {
            if (response.success) {
              //se ok signup richiamo la login in auto,matico
              return operasActions.viewMoreOperasSuccess({
                pageData: response.data,//artisti da aggiunge a già quelli presenti
              });
            } else {
              const esito: EsitoMessage = <EsitoMessage>{
                ok: true,
                message: response.message,
                //                 code?:string;
                // data?:any;
                // message?:string;
                // statusCode?:string;
                // success?:boolean;
              };
              return operasActions.viewMoreOperasFailure({ esito: esito });
            }
          }),
          catchError((error: any) =>
            of(
                operasActions.findOperasFailure({
                esito: {
                  ok: false,
                  message: error.message,
                },
              })
            )
          )
        );
      })
    )
  );
    }