import { createAction, props } from '@ngrx/store';
import { Credentials, ResetPwd, User, UserSignUp } from '../entity/user.entity';



export const USER_LOGIN = '[Login Page] Login';
export const USER_LOGIN_SUCCESS = '[Login Page] Login Success';
export const USER_LOGIN_FAILURE = '[Login Page] Login Failure';

export const GO_TO_USER_SIGN_UP = '[Subscribe Page] Go To Sign Up';
export const USER_SIGN_UP = '[Login Page] Sign up';
export const USER_SIGN_UP_SUCCESS = '[Login Page] Sign up Success';
export const USER_SIGN_UP_FAILURE = '[Login Page] Sign up Failure';


export const GOTO_RESET_PASSWORD = '[Login Page] Go To Reset Password';
export const RESET_PASSWORD = '[Login Page] Reset Password';
export const RESET_PASSWORD_SUCCESS = '[Login Page] Reset Password Success';
export const RESET_PASSWORD_FAILURE = '[Login Page] Reset Password Failure';

export const FB_LOGIN = '[Login Page] FB Login';
export const FB_LOGIN_SUCCESS = '[Login Page] FB Login Success';
export const FB_LOGIN_FAILURE = '[Login Page] FB Login Failure';
export const FB_LOGOUT = '[Logout Page] FB Logout';

export const LOG_OUT = '[Logout Page] Logout';








/************************
 *  LOGIN
 ************************/

export const login = createAction(
  USER_LOGIN,
  props<{credentials: Credentials}>()
);

export const loginSuccess = createAction(
  USER_LOGIN_SUCCESS,
  props<any>()
)

export const loginFailure = createAction(
  USER_LOGIN_FAILURE,
  props<{message: string}>()
)


/************************
 *  SIGN_UP
 ************************/
export const goToSignUp = createAction(
  GO_TO_USER_SIGN_UP,
);

export const signUp = createAction(
  USER_SIGN_UP,
  props<{userSignUp: UserSignUp}>()
);

export const signUpSuccess = createAction(
  USER_SIGN_UP_SUCCESS
)

export const signUpFailure = createAction(
  USER_SIGN_UP_FAILURE,
  props<{message: string}>()
)


/************************
 *  RESET PASSWORD
 ************************/

export const goToResetPwd = createAction(
  GOTO_RESET_PASSWORD
);


export const resetPwd = createAction(
  RESET_PASSWORD,
  props<{resetPwd: ResetPwd}>()
);

export const resetPwdSuccess = createAction(
  RESET_PASSWORD,
  props<any>()
)

export const resetPwdFailure = createAction(
  RESET_PASSWORD,
  props<{message: string}>()
)

/************************
 *  FACEBOOK LOGIN
 ************************/

export const facebookLogin = createAction(
  FB_LOGIN
);

export const facebookLoginSuccess = createAction(
  FB_LOGIN_SUCCESS,
  props<{user:User}>()
);

export const facebookLoginFailure = createAction(
  FB_LOGIN_FAILURE,
  props<{message: string}>()
);

// export const facebookJWTLoginSuccess = createAction(
//   FB_JWT_LOGIN_SUCCESS,
//   props<{user:User,result:any}>()
// );



/************************
 *  LOGOUT
 ************************/


export const logout = createAction(
  LOG_OUT
);

