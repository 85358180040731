export class RoutingDataType {
  public static home = 'home';
  public static login = 'login';
  public static subscribe = 'subscribe';
  public static resetPwd = 'forgotpwd';
  public static location = 'contacts';
  public static video = 'video';
  public static journal = 'journal';
  



  public static periodic = 'periodic';

  public static painters = 'painters';
  public static sculptors = 'sculptors';
  public static poets = 'poets';
  public static musiclyricsdance = 'musiclyricsdance';
  public static artcriticals = 'artcriticals';
  public static tour = 'tour';
  public static aboutUs = 'aboutUs';
  public static show = 'show';

  public static history = 'history';
  public static eubulletin = 'eubulletin';
  public static statuto = 'statuto';
  public static sponsor = 'sponsor';
  public static contacts = 'contacts';

}


export class RoutingLayoutType {
  public static LIST_URL = 'list';
  public static DETAIL_URL = 'detail';
  public static CREATE_URL = 'create';
  public static EDIT_URL = 'edit';
}

export class RoutingActionType {
  public static EDIT_URL = 'edit';
  public static VIEW_URL = 'view';
  public static CREATE_URL = 'create';
}
