import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  FacebookLoginProvider,
  SocialAuthService,
  SocialUser
} from "angularx-social-login";
import { RoutingDataType } from "app/shared/routing-action-type";
import { AuthService } from "app/shared/services/auth.service";
import { Observable, of } from "rxjs";
import { catchError, concatMap, exhaustMap, map, tap } from "rxjs/operators";
import * as userActions from "../actions";
import { User } from "../entity";
import { UserDto } from "../entity/user.entity";
import { BeResponse } from "./../entity/response.entyt";
import { CommonEffects } from "./common.effects";

@Injectable()
export class UserEffects extends CommonEffects {
  constructor(
    private actions$: Actions,
    private readonly router: Router,
    private authService: AuthService,
    private socialAuthService: SocialAuthService
  ) {
    super();
  }

  // userLogin$ = createEffect(() =>
  //   this.actions$.pipe(
  //     //ofType(userActions.login),
  //     exhaustMap(action =>
  //       this.appService.login(action.user).pipe(
  //         map(response => userActions.loginSuccess(response)),
  //         catchError((error: any) => of(userActions.loginFailure(error))))
  //     )
  //   )
  // );

  userLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.login),
      exhaustMap((action) => {
        return this.authService.signIn(action.credentials).pipe(
          map((response: BeResponse) => {
            if (response.success) {
              //recreate user and set adata to state

              let user: User = UserDto.toDto(response.data);
              user.tokenClaimObj = this.authService.decodeJwtForUser(
                response.data.token
              );
              //this.authService.decodeJwtForUser(user);

              //decodificar eil token pe ri ruoli e settare i ruoli all'utente

              return userActions.loginSuccess({
                user: user,
                response: response,
              });
            } else {
              return userActions.loginFailure({ message: response.message });
            }
          }),
          catchError((error: any) => of(userActions.loginFailure(error)))
        );
      })
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.loginSuccess),
        tap(() => this.router.navigate([`/${RoutingDataType.home}`]))
      ),
    { dispatch: false } //blocco la dispatch dello usr name
  );    

  goToSignUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.goToSignUp),
      tap(() => this.router.navigate([`/${RoutingDataType.subscribe}`]))
    ),
    { dispatch: false } 
  );


  userSignUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.signUp),
      exhaustMap((action) => {
        return this.authService.signUp(action.userSignUp).pipe(
          map((response: BeResponse) => {
            if (response.success) {
              //se ok signup richiamo la login in auto,matico
              return userActions.signUpSuccess();
            } else {
              return userActions.signUpFailure({ message: response.message });
            }
          }),
          catchError((error: any) => of(userActions.signUpFailure({ message: error.message })))
        );
      })
    )
  );

  signUpSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.signUpSuccess),
      tap(() => this.router.navigate([`/${RoutingDataType.login}`]))
    ),
    { dispatch: false } 
  );

  goToResetPwd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.goToResetPwd),
      tap(() => this.router.navigate([`/${RoutingDataType.resetPwd}`]))
    ),
    { dispatch: false } 
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.resetPwd),
      exhaustMap((action) => {
        return this.authService.resetPwd(action.resetPwd.email).pipe(
          map((response: BeResponse) => {
            if (response.success) {
              //se ok signup richiamo la login in automatico
              return userActions.resetPwdSuccess(response);
            } else {
              return userActions.resetPwdFailure({ message: response.message });
            }
          }),
          catchError((error: any) => of(userActions.resetPwdFailure(error)))
        );
      })
    )
  );

  //richiamta se lo user non è già stato richiesto
  // facebookLogin$ =  createEffect(() => this.actions$
  //    .pipe(
  //     ofType(userActions.facebookLogin),
  //     tap(() => of(this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID))),
  //    ),
  //    { dispatch:false}
  // );

  // goToLogin$ = createEffect(() => this.actions$
  //    .pipe(
  //     ofType(userActions.goTologin),
  //     tap(action => {
  //       this.router.navigate([`/${RoutingDataType.login}`])
  //     })
  //    ),
  //    { dispatch:false}
  // );

  facebookLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.facebookLogin),
        map(() =>
          of(this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID))
        ),
        concatMap(() =>
          this.socialAuthService.authState.pipe(
            map((socialUser: SocialUser) => {
              if (socialUser) {
                return UserDto.fromFbDto(socialUser);
              } else {
                return null;
              }
            }),
            concatMap((user: User) => {
              if (user) {
                return this.obtainRegisterAndJwt(user) //registra l'utente in wp e ottiene il Jwt
                  .pipe(
                    map((response: BeResponse) => {
                      if (this.checkResponse(response)) {
                        let userUpdate = JSON.parse(JSON.stringify(user));
                        userUpdate.tokenClaimObj = this.authService.decodeJwtForUser(
                          response.data.token
                        );
                        return userActions.facebookLoginSuccess({
                          user: userUpdate,
                        });
                      }
                    })
                  );
              } else {
                //utente non loggato rilancio la
                return of(userActions.facebookLogin());
              }
            }),
            //catchError((error: any) => of(userActions.loginFailure(error))),
            catchError((err) => {
              return of(userActions.loginFailure({ message: err.message }));
            })
          )
        )
      ),
    { dispatch: true }
  );

  facebookLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.facebookLoginSuccess),
        tap(() => this.router.navigate([`/${RoutingDataType.home}`]))
      ),
    { dispatch: false } //blocco la dispatch dello usr name
  );

  // facebookLoginSuccess$ =  createEffect(() => this.actions$
  //    .pipe(
  //         ofType(userActions.facebookLoginSuccess),
  //         mergeMap(action => this.obtainRegisterAndJwt(action.user)
  //           .pipe(
  //             map(user =>  {return userActions.facebookJWTLoginSuccess({user:user,result:undefined})}),
  //             catchError((error) => of(userActions.loginFailure({message:"undefined"})))
  //           )
  //         ),
  //    ),
  //    { dispatch: true }  //blocco la dispatch dello usr name
  // );

  obtainRegisterAndJwt(user: User): Observable<any> {
    return this.authService.autenticateFbAccessToken(user.accessToken).pipe(
      map((response: BeResponse) => {
        response = response;
        //ottiene il jwt di autenticazione
        return response;

        if (this.checkError(response)) {
          //throe
        } else {
          let userUpdate = JSON.parse(JSON.stringify(user));
          userUpdate.extraInfo = this.authService.decodeJwtForUser(
            response.data.token
          );
          return userUpdate; //userActions.facebookJWTLoginSuccess({user:user,result:response});
        }
      })
    );
  }

  checkError(response: BeResponse) {
    return false;
  }

  // facebookJWTLoginSuccess$ =  createEffect(() => this.actions$
  //    .pipe(
  //     ofType(userActions.facebookJWTLoginSuccess),
  //     tap(() => {
  //       this.router.navigate([`/${RoutingDataType.home}`])
  //     }),
  //    ),
  //    { dispatch:false}
  // );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.logout),
        tap(() => {
          //this.socialAuthService.signOut();
          this.socialAuthService
            .signOut()
            .then()
            .catch(() => {
              console.log("Facebbok login error");
            });
          sessionStorage.clear();
          this.router.navigate([`/${RoutingDataType.login}`]);
        })
      ),
    { dispatch: false }
  );

  // facebookLogin$ =  createEffect(() => this.actions$
  // .pipe(
  //  ofType(userActions.facebookLogin),
  //  exhaustMap(action => {

  //   FB.getLoginStatus(({authResponse}) => {
  //     if (authResponse) {
  //         //se risulta connesso allora va a richiedere il jwt di autorizzazone
  //         // return this.authService.signInForFb(authResponse.accessToken)
  //         // .pipe(
  //         //   map((response:BeResponse) => {

  //         //     let user:User = UserDto.toDto(response.data);
  //         //     //this.jwtTokenService.decodeJwtForUser(user);

  //         //       //decodificar eil token pe ri ruoli e settare i ruoli all'utente

  //         //     return userActions.loginSuccess({user:user, response:response});

  //         //   }),
  //         //   catchError((error: any) => of(userActions.loginFailure(error)))
  //         // )

  //     } else {
  //         //non è loggato a fb allora deve visualizzare il popup di fb
  //         // return this.authService.facebookLogin()
  //         // .pipe(//access token potrebbe essere anche EMPTY
  //         //   concatMap((accessToken:string) => this.authService.signInForFb(accessToken)))
  //         //   .pipe(
  //         //     map((response:BeResponse) => {
  //         //       let user:User = UserDto.toDto(response.data);
  //         //       return userActions.loginSuccess({user:user, response:response});
  //         //     }),
  //         //     catchError((error: any) => of(userActions.loginFailure(error)))
  //         //   )
  //         // );
  //       }
  //   });
}
