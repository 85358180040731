import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgSpinnerModule } from 'ng-bootstrap-spinner';
import { LightboxModule } from 'ngx-lightbox';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ArtistGalleryComponent } from './components/artists/artist-gallery/artist-gallery.component';
import { ItemOperaComponent } from './components/artists/artist-gallery/item-opera/item-opera.component';
import { CommonPageHeaderComponent } from './components/common-page-header/common-page-header.component';
import { GenericEventComponent } from './components/generic-event/generic-event.component';
import { ItemJournalComponent } from './components/item-journal/item-journal.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginAvatarComponent } from './components/login-avatar/login-avatar.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { SearchComponent } from './components/search/search.component';
import { SeoCanonicalUrlComponent } from './components/seo/seo-canonical-url/seo-canonical-url.component';
import { SeoDescriptionComponent } from './components/seo/seo-description/seo-description.component';
import { SeoNoIndexComponent } from './components/seo/seo-no-index/seo-no-index.component';
import { SeoStatusCodeComponent } from './components/seo/seo-status-code/seo-status-code.component';
import { SeoTitleComponent } from './components/seo/seo-title/seo-title.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { ValidInputComponent } from './components/valid-input/valid-input.component';
import { CategoriesPipe } from './pipes/categories.pipe';
import { DateToCalendarPipe } from './pipes/date-to-calendar.pipe';
import { DateToMouthPipe } from './pipes/date-to-mouth.pipe';
import { OperaDimensionPipe } from './pipes/opera-dimension.pipe';
import { RemoveBrTagPipe } from './pipes/remove-br-tag.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';

// function translateHttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
//   return new TranslateHttpLoader(new HttpClient(httpBackend),'./assets/i18n/app/', '.json');

// }

/*
export function translateHttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
  //return new TranslateHttpLoader(new HttpClient(httpBackend));
  const http = new HttpClient(httpBackend);

  return new TranslateHttpLoader(http, 'assets/i18n', '.json');

}*/

@NgModule({
  declarations: [
    /*NavBarComponent,
    TopBarComponent,
    FooterBarComponent,
    LoginDialogComponent*/
    SearchComponent,
    ItemOperaComponent,
    CommonPageHeaderComponent,
    CategoriesPipe,
    LoginAvatarComponent,
    LoginDialogComponent,
    LoaderComponent,
    SafeUrlPipe,
    OperaDimensionPipe,
    ToasterComponent,
    GenericEventComponent,
    RemoveBrTagPipe,
    ItemJournalComponent,
    DateToMouthPipe,
    DateToCalendarPipe,
    ValidInputComponent,
    ArtistGalleryComponent,
    SafeHtmlPipe,
    SeoTitleComponent,
    SeoStatusCodeComponent,
    SeoNoIndexComponent,
    SeoDescriptionComponent,
    SeoCanonicalUrlComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    LightboxModule,
    CarouselModule,
    NgSpinnerModule,
   

    //I18nModule,
    //TranslateModule,
    TranslateModule,

    /*TranslateModule.forChild({
      loader: {
        provide: TranslateModule.forChild({}),
        useFactory: (translateHttpLoaderFactory),
        deps: [HttpBackend]
      },
      isolate: true
    }),*/
  ],
  exports: [
    SearchComponent,
    CommonModule,
    /*NavBarComponent,
    TopBarComponent,
    FooterBarComponent,*/
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    LightboxModule,
    CarouselModule,
    ItemOperaComponent,
    CommonPageHeaderComponent,
    CategoriesPipe,
    LoginAvatarComponent,
    LoginDialogComponent,
    LoaderComponent,
    SafeUrlPipe,
    OperaDimensionPipe,
    ToasterComponent,
    GenericEventComponent,
    RemoveBrTagPipe,
    ItemJournalComponent,
    DateToMouthPipe,
    ValidInputComponent,
    ArtistGalleryComponent,
    SafeHtmlPipe,
    SeoTitleComponent,
    SeoStatusCodeComponent,
    SeoNoIndexComponent,
    SeoDescriptionComponent,
    SeoCanonicalUrlComponent
   


    //TranslateModule
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
        ngModule: SharedModule
    };
}
}
