import { Component, Input, OnInit } from '@angular/core';
import { Journal } from 'app/+state/entity/journal.entity';

@Component({
  selector: 'app-item-journal',
  templateUrl: './item-journal.component.html',
  styleUrls: ['./item-journal.component.scss']
})
export class ItemJournalComponent implements OnInit {

  @Input()
  journal:Journal;

  constructor() { }

  ngOnInit(): void {
  }

}
