import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FacebookLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import * as fromRoot from 'app/+state';
import { User } from 'app/+state/entity';
import { Credentials, UserSignUp } from 'app/+state/entity/user.entity';
import * as storage from 'app/+state/state/storage';
import { environment } from 'environments/environment';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, finalize, map } from 'rxjs/operators';
import { BeResponse } from './../../+state/entity/response.entyt';
import { TokenClaimObj } from './../../+state/entity/user.entity';
import { JwtTokenService } from './auth/jwt-token.service';
import { CrudService } from './core/crud.service';
import { ErrorService } from './core/error.service';
import { LoaderService } from './core/loader.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends CrudService<User>{

  private accountSubject: BehaviorSubject<User>;
  public account: Observable<User>;



  // const backendURL = 'accademia-europea-delle-arti.local/?rest_route=/simple-jwt-login/v1/';
// const backendProtocol = 'http://';


  constructor(public httpClient: HttpClient,
    public jwtTokenService:JwtTokenService,
    public errorService: ErrorService,
    public loaderService: LoaderService,
    private socialAuthService: SocialAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>) {
    super(httpClient, `${environment.endpoint}`, '/jwt-auth/v1', errorService, loaderService);
    this.accountSubject = new BehaviorSubject<User>(null);
    this.account = this.accountSubject.asObservable();
  }

  public getJwtToken():string {
      return this.jwtTokenService.getJWTToken();
  }


  public decodeJwtForUser(token:any):TokenClaimObj {
    return this.jwtTokenService.decodeJwtForUser(token);
  }

  private getUser():User {
    return storage.getItem('user').user;
  }


  public checkUserIsLogged():Observable<String> {

    //onservable che mi ritroan L'utente
    //observalbe che verifica la validità del token, se il token non è valido si deve richiedere un'altro token, se è present eun errore allora false e si torna alla login
    const checkUser$ = this.store.select(fromRoot.userLogin)
    .pipe(
      map(data => data.user)
    );  

    return checkUser$
    .pipe(
      concatMap(user => {

        if(user) {
          return this.validateToken();
        }
        else {
          return of(null);//nessun token ritornato dalla validazione
        }
      }),
      catchError(error => {throw error}),
      // map((response:BeResponse) => {
      //   return response.success;
      // })
      //finalize(() => this.loaderService.hide())
      );
    

  }

  private isTokenExpired(response:BeResponse):boolean {
     
    // import {JwtHelperService} from '@auth0/angular-jwt';
    // .
    // .
    // constructor(private jwtHelper: JwtHelperService) { }
    
    // ngOnInit() {
    //   if (this.jwtHelper.isTokenExpired(token)) {
    //     // token expired 
    //   } else {
    //     // token valid
    //   }
    // }

    return true;

  }

  public refreshToken() {
    //  return this.http.post<any>(`${config.apiUrl}/refresh`, {
    //    'refreshToken': this.getRefreshToken()
    //  }).pipe(tap((tokens: Tokens) => {
    //    this.storeJwtToken(tokens.jwt);
    //  }));

    return this.httpClient.post<BeResponse>(`${this.baseUrl}${this.endpoint}/token/refresh`, {
      token: this.jwtTokenService.getJWTToken(),//passo il token espirato oopure crear eun refrsh token
    }, this.httpOptions).pipe(
      map((response:BeResponse) => {
        //response.data.token;//assegnare il nuovo token all'utente
        this.jwtTokenService.updateToken(response.data.token);
      })
    )
  }

  private validateToken():Observable<string> {
    //utilizza l'interceptor Beare per vlaidar eil token

 
    //inserir eil beaerer per la vlaidate
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.jwtTokenService.getJWTToken()}`
      })
    };

    return this.httpClient.post<BeResponse>(`${this.baseUrl}${this.endpoint}/validate`, httpOptions)
    .pipe(
      concatMap((response: BeResponse) => {

          if(this.checkExpiredTokenResponse(response)) {
            return this.httpClient.post<BeResponse>(`${this.baseUrl}${this.endpoint}/token/refresh`, {
              token: this.jwtTokenService.getJWTToken(),//passo il token espirato
            }, this.httpOptions).pipe(
              map((response:BeResponse) => response.data.token)
            )
          }
          else {
            return of(response.data.token);
          }
        }
      ),
      catchError(err => {
        console.log(err);
        return of(null);//no error check error
      }),
      //finalize(() => this.loaderService.hide())
    ); 
  } 

  private checkExpiredTokenResponse(response:BeResponse) {
    return false;
//     {
//       "success": true,
//       "statusCode": 200,
//       "code": "jwt_auth_valid_token",
//       "message": "Token is valid",
//       "data": []  
//   }

//   {
//     "success": false,
//     "statusCode": 403,
//     "code": "jwt_auth_bad_config",
//     "message": "JWT is not configurated properly.",
//     "data": []
// }
// {
//   "success": false,
//   "statusCode": 403,
//   "code": "jwt_auth_no_auth_header",
//   "message": "Authorization header not found.",
//   "data": []
// }
// {
//   "success": false,
//   "statusCode": 403,
//   "code": "jwt_auth_bad_iss",
//   "message": "The iss do not match with this server.",
//   "data": []
// }
// {
//   "success": false,
//   "statusCode": 403,
//   "code": "jwt_auth_invalid_token",
//   "message": "Signature verification failed",
//   "data": []
// }
// {
//   "success": false,
//   "statusCode": 403,
//   "code": "jwt_auth_bad_request",
//   "message": "User ID not found in the token.",
//   "data": []
// }
// {
//   "success": false,
//   "statusCode": 403,
//   "code": "jwt_auth_user_not_found",
//   "message": "User doesn't exist",
//   "data": []
// }
// {
//   "success": false,
//   "statusCode": 403,
//   "code": "jwt_auth_invalid_token",
//   "message": "Expired token",
//   "data": []
// }
  }


  public isLoggedIn():boolean {

    //verifica che l'utente sia presente e che il token non sia expiring
    //o call la validate token per verificare se il token è ok
    return this.getUser() && !this.jwtTokenService.isTokenExpired();
  }

  // public getJWTToken():string {
  //   this.jwtTokenService.get
  //   return this.localStorageService.getUserData().token;
  // }

  public obtainAccessToken() {
    
      //da ottenere sempre dal server
  }


  public signUp(credential: UserSignUp) {

    return this.httpClient.post<BeResponse>(`${this.baseUrl}/user/v1/users/register`, {
      //username: credential.username,
      email: credential.email,
      password: credential.password,
      firstname: credential.firstName,
      lastname:credential.lastName
    }, this.httpOptions)
    .pipe(
      map((response: BeResponse) => {

  

        // response.code
        // response.data 
        // response.message
        // response.statusCode
        // response.message
         //manipolare l'utente, veriicar eanche  rusltati della repsonse per lanciare le opportune azioni
        //response['user'] = plainToClass(User, response['data']);
        return response;
        //trnaslate user data
      }),
      catchError(err => {
        console.log(err);
        return throwError(err); 
        //return of([]);

    //     if(err instaceof HttpErrorResponse)

    //     let result:BeResponse = new BeResponse();
    //     result.code = 


    //     code?:string;
    // data?:any;
    // message?:string;
    // statusCode?:string;
    // success?:boolean;
      })
    );
  }

  public resetPwd(email: string) {

    // let r:BeResponse = <BeResponse> {
    //   success:true
    // }

    // return of(r);
    //richiedere il token da salvare in sessione
    return this.httpClient.post<BeResponse>(`${this.baseUrl}/user/v1/users/lostpassword`, {
      user_login: email
    }, this.httpOptions)
    .pipe(
      map((response: BeResponse) => {

  

        // response.code
        // response.data 
        // response.message
        // response.statusCode
        // response.message
         //manipolare l'utente, veriicar eanche  rusltati della repsonse per lanciare le opportune azioni
        //response['user'] = plainToClass(User, response['data']);
        return response;
        //trnaslate user data
      }),
      catchError(err => {
        console.log(err);
        return of([]);
      })
    );
  }



  public signOut() {
    //revoke del token, clear user data
    //this.jwtTokenService.setToken(null);
    //this.localStorageService.setUserData(null);
  }

  public signIn(credential: Credentials) {
    //richiedere il token da salvare in sessione
    return this.httpClient.post<BeResponse>(`${this.baseUrl}${this.endpoint}/token`, {
      username: credential.email,
      password: credential.password
    }, this.httpOptions)
    .pipe(
      map((response: BeResponse) => {
        return response;
      }),
      catchError(err => {
        console.log(err);
        return of([]);
      })
    );    
    // .subscribe(
    //   (tokenData:any) => {
    //     //token ottenuto successivamente alla sottoscrizione
    //     this.jwtTokenService.setToken(tokenData.data.token);
    //     //costruire i dati da aasociare all'utente recuperati dal token
    //     this.localStorageService.setUserData(this.jwtTokenService.getUser());
    //     console.log(tokenData.data);
    // });
        // this is just the HTTP call,
        // we still need to handle the reception of the token
        //shareReplay());
  }


 /*********************************************
  * FACEBOOK
  ********************************************/

 public get accountValue(): User {
  return this.accountSubject.value;
 }

 login() {
  // login with facebook then authenticate with the API to get a JWT auth token
  // this.facebookLogin()
  //     .pipe(concatMap((accessToken:string) => this.apiAuthenticate(accessToken)))
  //     .subscribe(() => {
  //         // get return url from query parameters or default to home page
  //         const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  //         this.router.navigateByUrl(returnUrl);
  //     });
}

facebookLogin():Observable<SocialUser> {


  return from(this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID))
  .pipe(
    concatMap(any => {

      console.log(any);

      return of(null);
      // if(user) {
      //     //this.getJWTToken richiamre il backend per ottenre il JWT passando l'access token
      //     return user;

      // }
      // else {
      //   return user;//nno autenticato
      // }
    })
  )

  // console.log(user);  
  // user.then((data)=>{
  //     console.log("Promise resolved with: " + JSON.stringify(data));
  //   }).catch((error)=>{
  //     console.log("Promise rejected with " + JSON.stringify(error));
  //   });


    // this.socialAuthService.authState.subscribe((user) => {
      
    //   let user1 = user;
    //   //this.user = user;
    //   //this.loggedIn = (user != null);
    // });



  // login with facebook and return observable with fb access token on success
  // return from(new Promise<fb.StatusResponse>(resolve => FB.login(resolve)))
  //     .pipe(concatMap(({ authResponse }) => {
  //         if (!authResponse) return EMPTY;
  //         return of(authResponse.accessToken);
  //     }));
}

  // apiAuthenticate(accessToken: string) {
  //   // authenticate with the api using a facebook access token,
  //   // on success the api returns an account object with a JWT auth token
  //   return this.httpClient.post<any>(`${this.baseUrl}/authenticate`, { accessToken })
  //       .pipe(map(account => {
  //           this.accountSubject.next(account);
  //           this.startAuthenticateTimer();
  //           return account;
  //       }));
  // }

  public autenticateFbAccessToken(accessToken: string) {
    //richiedere il token da salvare in sessione
    return this.httpClient.post<BeResponse>(`${this.baseUrl}${this.endpoint}/token`, {
      custom_auth : accessToken
    }, this.httpOptions)
    .pipe(
      map((response: BeResponse) => {
        return response;
      }),
      catchError(err => {
        console.log(err);
        return of([]);
      })
    ); 
  } 

  logout() {
      // // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
      // FB.api('/me/permissions', 'delete', null, () => FB.logout());
      // this.stopAuthenticateTimer();
      // this.accountSubject.next(null);
      // this.router.navigate(['/login']);
  }

  getAll() {
    return this.httpClient.get<Account[]>(this.baseUrl);
  } 

  getById(id) {
    return this.httpClient.get<Account>(`${this.baseUrl}/${id}`);
  }

  update(id, params) {
      return this.httpClient.put(`${this.baseUrl}/${id}`, params)
          .pipe(map((account: any) => {
              // update the current account if it was updated
              if (account.id === this.accountValue.id) {
                  // publish updated account to subscribers
                  account = { ...this.accountValue, ...account };
                  this.accountSubject.next(account);
              }
              return account;
          }));
  }

  delete(id: number) {
      return this.httpClient.delete(`${this.baseUrl}/${id}`)
          .pipe(finalize(() => {
              // auto logout if the logged in account was deleted
              if (id === this.accountValue.id)
                  this.logout();
          }));
  }


   // helper methods

  //  private authenticateTimeout;

  //  private startAuthenticateTimer() {
  //      // parse json object from base64 encoded jwt token
  //      const jwtToken = JSON.parse(atob(this.accountValue.token.split('.')[1]));

  //      // set a timeout to re-authenticate with the api one minute before the token expires
  //      const expires = new Date(jwtToken.exp * 1000);
  //      const timeout = expires.getTime() - Date.now() - (60 * 1000);
  //      const { accessToken } = FB.getAuthResponse();
  //      this.authenticateTimeout = setTimeout(() => {
  //          this.apiAuthenticate(accessToken).subscribe();
  //      }, timeout);
  //  }

  //  private stopAuthenticateTimer() {
  //      // cancel timer for re-authenticating with the api
  //      clearTimeout(this.authenticateTimeout);
  //  }



}

/*
login(email:string, password:string ) {
  return this.http.post<User>('/api/login', {email, password})
      .do(res => this.setSession)
      .shareReplay();
}

private setSession(authResult) {
  const expiresAt = moment().add(authResult.expiresIn,'second');

  localStorage.setItem('id_token', authResult.idToken);
  localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
}

logout() {
  localStorage.removeItem("id_token");
  localStorage.removeItem("expires_at");
}

public isLoggedIn() {
  return moment().isBefore(this.getExpiration());
}

isLoggedOut() {
  return !this.isLoggedIn();
}

getExpiration() {
  const expiration = localStorage.getItem("expires_at");
  const expiresAt = JSON.parse(expiration);
  return moment(expiresAt);
}


public httpClient: HttpClient,
public baseUrl: string,
public endpoint: string,
public errorService: ErrorService,
public loaderService: LoaderService
) { }

public post(item: T): Observable<T> {
this.loaderService.show();
return this.httpClient.post<T>(`${this.baseUrl}${this.endpoint}`, JSON.stringify(item), this.httpOptions)
  .pipe(
    map((data: any) => data as T),
    catchError((err) => {
      this.error(err);
      throw err;
    }),
    finalize(() => this.loaderService.hide()));
}*/
