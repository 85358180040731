import { Injectable } from '@angular/core';

import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(
    private toastService: ToastService
  ) { }

  public showError(codice: number | string) {
    const message = codice as string;
    this.toastService.showMessage(message);
  }
}
