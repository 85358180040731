import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateToMouth'
})
export class DateToMouthPipe  implements PipeTransform {

  constructor( private readonly translateService:TranslateService){}

  transform(date:string): string {
    
    var dateParts = date.split("/");

    const translate:string = this.translateService.instant('common.month.' + dateParts[1]);

    var dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]); 
  
    return translate.concat(' ').concat(dateParts[2]);
  }

}
