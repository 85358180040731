import { createAction, props } from "@ngrx/store";
import { WpJournalPageData } from "app/+state/entity/journal.entity";
import { EsitoMessage } from "../entity";
import { FilterJournal, JournalPageData } from "../entity/journal.entity";

export const FIND_JOURNAL = "[Journal Page] Find Journal";
export const FIND_JOURNAL_SUCCESS = "[Journal Page] Find Journal Failure";
export const FIND_JOURNAL_FAILURE = "[Journal Page] Find Journal Success";

export const LOAD_DATA_PAGE_JOURNAL = "[Journal Page] View Data Pege Journal";
export const LOAD_DATA_PAGE_JOURNAL_SUCCESS =
  "[Journal Page] View Data Pege Journal Success";
export const LOAD_DATA_PAGE_JOURNAL_FAILURE =
  "[Journal Page] View Data Pege Journal Failure";

export const ASK_FOR_ANOTHER_NUMBER = "[Journal Page] Ask For Another Number";
export const ASK_FOR_ANOTHER_NUMBER_SUCCESS =
  "[Journal Page] Ask For Another Number Success";
export const ASK_FOR_ANOTHER_NUMBER_FAILURE =
  "[Journal Page] Ask For Another Number Failure";

export const findJournal = createAction(
  FIND_JOURNAL,
  props<{ filter: FilterJournal }>()
);

export const findJournalSuccess = createAction(
  FIND_JOURNAL_SUCCESS,
  props<{ pageData: JournalPageData }>()
);

export const findJournalFailure = createAction(
  FIND_JOURNAL_FAILURE,
  props<{ esito: EsitoMessage }>()
);

export const loadJournalPageData = createAction(LOAD_DATA_PAGE_JOURNAL);

export const loadJournalPageDataSuccess = createAction(
  LOAD_DATA_PAGE_JOURNAL_SUCCESS,
  props<{ data: WpJournalPageData }>()
);

export const loadJournalPageDataFailure = createAction(
  LOAD_DATA_PAGE_JOURNAL_FAILURE,
  props<{ data: WpJournalPageData }>()
);

export const askForAnotherNumber = createAction(
  ASK_FOR_ANOTHER_NUMBER,
  props<{ message: string }>()
);

export const askForAnotherNumberSuccess = createAction(
  ASK_FOR_ANOTHER_NUMBER_SUCCESS
);

export const askForAnotherNumberFailure = createAction(
  ASK_FOR_ANOTHER_NUMBER_FAILURE,
  props<{ esito: EsitoMessage }>()
);
