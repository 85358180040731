import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ){}

  isNoIndex(): boolean {
    const meta = this.document.head.querySelector('meta[name="robots"]');
    return meta && meta.getAttribute('content').indexOf('noindex') > -1;
  }

  removeCanonicalUrl(){
    const link = this.document.head.querySelector('link[rel="canonical"]');
    if (link) {
      this.document.head.removeChild(link);
    }
  }

  setCanonicalUrl(url: string){
    if (!this.document || !this.document.head || this.isNoIndex()) {
      return;
    }

    let link = this.document.head.querySelector('link[rel="canonical"]');
    if (!link) {
      link = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.document.head.appendChild(link);
    }

    link.setAttribute('href', url.toString());
  }

  setDescription(description: string){
    this.setMetaContent('description', description);
  }

  setNoIndex(){
    // canonical url and noindex cannot live together:
    // https://www.seroundtable.com/google-noindex-rel-canonical-confusion-26079.html
    this.removeCanonicalUrl();

    this.setMetaContent('robots', 'noindex, follow');
  }

  // https://prerender.io/documentation/best-practices
  setStatusCode(code: string){
    this.setMetaContent('prerender-status-code', code);
  }

  setTitle(title: string){
    let element = this.document.head.querySelector('title');
    if(!element){
      element = this.document.createElement('title');
      this.document.head.appendChild(element);
    }
    element.innerText = title;
  }

  private setMetaContent(name: string, content: string){
    let meta = this.document.head.querySelector('meta[name="' + name + '"]');
    if (!meta) {
      meta = this.document.createElement('meta');
      meta.setAttribute('name', name);
      this.document.head.appendChild(meta);
    }
    meta.setAttribute('content', content);
  }
}




/*export class SeoService {

  constructor(
    @Inject(DOCUMENT) private dom,
    private titleSvc: Title,
    private metaSvc: Meta,
  ) { }


  updateTitle(title: string){
    this.titleSvc.setTitle(title)
  }

  updateDescription(content: string) {
    this.metaSvc.updateTag({ name: 'description', content })
  }

  //
  //A canonical tag (aka "rel canonical") is a way of telling search engines that a specific URL represents the master copy of a page.
  // Using the canonical tag prevents problems caused by identical or "duplicate" content appearing on multiple URLs. 
  // Practically speaking, the canonical tag tells search engines which version of a URL you want to appear in search results.


  // utilizzare la cache o vedere se è già presente il link nel documento

  
  createCanonicalLink(url: string) {
    let link: HTMLLinkElement =
      this.dom.createElement('link');

    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
    this.dom.head.appendChild(link);
  }
}*/
