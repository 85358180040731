import {
  HttpErrorResponse, HttpEvent, HttpHandler,

  HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from 'app/shared/services/core/error.service';
import { LoaderService } from 'app/shared/services/core/loader.service';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private errorService: ErrorService,private loaderService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loaderService.show();
    return next.handle(request)
    .pipe(
     
      tap(response => {
          if('body' in response && response['body'].success === false){
            this.errorService.showError(response['body'].message);
          }
      }),      


      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          console.log('this is client side error');
          errorMsg = `Error: ${error.error.message}`;
          this.errorService.showError(errorMsg);
        }
        else {
          console.log('this is server side error');
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          this.errorService.showError(errorMsg);
        }
        console.log(errorMsg);
        return throwError(errorMsg);
      }),
      finalize(() => this.loaderService.hide())
    )
  }
}
