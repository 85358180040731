import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpBackend, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
//import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { NgDompurifyModule } from '@tinkoff/ng-dompurify';
import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { UserEffects } from 'app/+state/effects';
import { environment } from 'environments/environment';
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { metaReducers, reducers } from './+state';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { JWTInterceptor } from './core/interceptors/jwt.interceptor';
import { SeoInterceptor } from './core/interceptors/seo.interceptor';
import { ExamplesModule } from './examples/examples.module';
import { TopBarComponent } from './shared/components/top-bar/top-bar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { TranslateSharedService } from './shared/services/utils/translate-shared.service';
import { SharedModule } from './shared/shared.module';


export function HttpLoaderFactory(handler: HttpBackend) {
  //pe ri componenti figli del modulo riferire al path in shared module
  return new TranslateHttpLoader(new HttpClient(handler),  `${environment.i18nFolder}/app/`, '.json');
}

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'europeanaccademyofarts.local' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};





@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    TopBarComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule,
    ExamplesModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    CarouselModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true
      }
    }),
    EffectsModule.forRoot([UserEffects]),
    TranslateModule.forRoot({
      //defaultLanguage: 'en',
      //useDefaultLang: false,
      //missingTranslationHandler: {provide: MyMissingTranslationHandler, useClass: MyMissingTranslationHandler},
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend]
      },
      isolate: true
  }),
  SocialLoginModule,
  NgDompurifyModule
  //NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  exports: [
  ],
  providers: [
    TranslateSharedService,
    //{ provide: APP_INITIALIZER, useFactory: appInitializer, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SeoInterceptor, multi: true},
    { provide: 'window', useFactory: () => window},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          // {
          //   id: GoogleLoginProvider.PROVIDER_ID,
          //   provider: new GoogleLoginProvider(
          //     environment.facebookAppId
          //   )
          // },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppId)
          }
        ]
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy}
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
