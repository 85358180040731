// export interface WpCommonDto {
//   id:number;
//   title:string;
//   category:string;
//   content:string;//contenuto del post
//   link:string;//link alla pagina wordpress
//   author:string;//chi ha creato il post
// }




// export interface AvatarDto extends WpCommonDto {
//   filename:string;
//   url:string;
//   alt:string;
//   description:string;
//   caption:string;
//   uploaded_to:number;//indica l'id del post di cui il thubnail fà parte
//   mime_type:string;
//   icon:string;//url di default del media dell'immagine
//   width:number;
//   height:number;
//   sizes:AvatarSizes;
// }

// export interface AvatarSizes {
//   thumbnail: string; //url dell'immagine da utilizzare nelle preview
//   thumbnail_width: number;
//   thumbnail_height: number;
//     /*            "medium": "http://accademia-europea-delle-arti.local/wp-content/uploads/2020/12/Bruna-Sordi-2-245x300.jpg",
//                 "medium-width": 245,
//                 "medium-height": 300,
//                 "medium_large": "http://accademia-europea-delle-arti.local/wp-content/uploads/2020/12/Bruna-Sordi-2.jpg",
//                 "medium_large-width": 336,
//                 "medium_large-height": 411,
//                 "large": "http://accademia-europea-delle-arti.local/wp-content/uploads/2020/12/Bruna-Sordi-2.jpg",
//                 "large-width": 336,
//                 "large-height": 411,
//                 "1536x1536": "http://accademia-europea-delle-arti.local/wp-content/uploads/2020/12/Bruna-Sordi-2.jpg",
//                 "1536x1536-width": 336,
//                 "1536x1536-height": 411,
//                 "2048x2048": "http://accademia-europea-delle-arti.local/wp-content/uploads/2020/12/Bruna-Sordi-2.jpg",
//                 "2048x2048-width": 336,
//                 "2048x2048-height": 411,*/
// }


export class PageData<T> {
  //max_pages:string;//numero totale di pagine
  pageNumber:string;
  pageCount:string;
  data:Array<T>;

  public static readonly PAGE_SIZE = 20;
}

export class DateCalendar {
  day:string;
  month:string;
  year:string;
}

/*
export class PageData {
  active:string = "";
  currentPage:string = "1";

  constructor(_active:boolean = false,currentPage:string){
    this.active = _active ? "active" : "";
    this.currentPage = currentPage;
  }
}*/

//contiene i dati base del WP dataModel
export abstract class DataModel {
  id:number;
  title:string;
  author:string;//chi ha creato il post
  name:string;
  //category:Category;
  content:string;//contenuto del post
  link:string;//link alla pagina wordpress
  slug:string;


  public static compareById(v1: DataModel, v2: DataModel): boolean {
    return v1.id === v2.id;
  }



  protected static copyMatchingValues(target,source) {
    if(target) {
      return Object.keys(target).forEach(key => {
        if(source[key] !== undefined) {
          target[key] = source[key];
        }
      }
    );
    }
  }
}

//                     "title": "300483",
//                     "filename": "300483.pdf",
//                     "filesize": 76500,
//                     "url": "https://europeanaccademyofarts.local/wp-content/uploads/2021/02/300483.pdf",
//                     "link": "https://europeanaccademyofarts.local/artist_event/__trashed/attachment/300483/",
//                     "alt": "",
//                     "author": "1",
//                     "description": "",
//                     "caption": "",
//                     "name": "300483",
//                     "status": "inherit",
//                     "uploaded_to": 60,
//                     "date": "2021-02-06 13:52:35",
//                     "modified": "2021-02-06 13:52:35",
//                     "menu_order": 0,
//                     "mime_type": "application/pdf",
//                     "type": "application",
//                     "subtype": "pdf",
//                     "icon": "http://europeanaccademyofarts.local/wp-includes/images/media/document.png"

export abstract class FileModel extends DataModel {

  filename:string;
  //filesize:number;
  url:string;//dov eil file è stato fatto l'upload
  alt:string;
  description:string;
  caption:string;
  uploaded_to:number;//indica l'id del post di cui il thubnail fà parte
  mime_type:string;
  type: string;
  subtype: string;
  icon:string;//url di default del media dell'immagine
  width:number;
  height:number;
}

export abstract class AvatarDataModel extends FileModel {
 
  sizes:AvatarSizesDataModel;
}


export class AvatarSizesDataModel {
  thumbnail: string; //url dell'immagine da utilizzare nelle preview
  thumbnail_width: number;
  thumbnail_height: number;
  medium:string;
  medium_width: number;
  medium_height: number;
  medium_large: string;
  medium_large_width: number;
  medium_large_height: number;
  large: string;
  large_width: number;
  large_height: number;
                /*"1536x1536": "http://accademia-europea-delle-arti.local/wp-content/uploads/2020/12/Bruna-Sordi-2.jpg",
                "1536x1536-width": 336,
                "1536x1536-height": 411,
                "2048x2048": "http://accademia-europea-delle-arti.local/wp-content/uploads/2020/12/Bruna-Sordi-2.jpg",
                "2048x2048-width": 336,
                "2048x2048-height": 411,*/
}

export class WpMediaImageDataModel extends DataModel {
    alt_text:string;
    media_type: string;
    mime_type: string;
    media_details : WpMediaDetailsImage;
}

export class WpMediaDetailsImage {
  width:number;
  height:number;
  file:string
  sizes:WpMediaDetailsImageSizes;

}

export class WpMediaDetailsImageSizes {
  medium:WpMediaImageSizeInfo;
  thumbnail:WpMediaImageSizeInfo;
  full:WpMediaImageSizeInfo;

}

export class WpMediaImageSizeInfo {
  width: number;
  height: number;
  file: string;//nome del file el_sil-300x81.jpg
  mime_type: string;
  source_url: string;
}



  
//                     "id": 61,
//                     "title": "300483",
//                     "filename": "300483.pdf",
//                     "filesize": 76500,
//                     "url": "https://europeanaccademyofarts.local/wp-content/uploads/2021/02/300483.pdf",
//                     "link": "https://europeanaccademyofarts.local/artist_event/__trashed/attachment/300483/",
//                     "alt": "",
//                     "author": "1",
//                     "description": "",
//                     "caption": "",
//                     "name": "300483",
//                     "status": "inherit",
//                     "uploaded_to": 60,
//                     "date": "2021-02-06 13:52:35",
//                     "modified": "2021-02-06 13:52:35",
//                     "menu_order": 0,
//                     "mime_type": "application/pdf",
//                     "type": "application",
//                     "subtype": "pdf",
//                     "icon": "http://europeanaccademyofarts.local/wp-includes/images/media/document.png"





