import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromRoot from 'app/+state';
import { State } from 'app/+state';
import * as loginActions from 'app/+state/actions/login.actions';
import { CommonComponent } from 'app/shared/components/common/common.component';
import { RoutingDataType } from 'app/shared/routing-action-type';
import { Observable } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';



// @Component({
//   selector: 'app-modal-content',
//   template: `
//   <div class="modal-header">
//   <section class="signin-form">
//   <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
//   <h2>Sign in</h2>
//       <p class="hint-text">Sign in with your social media account</p>
//   <div class="social-btn text-center">
//     <a href="#" class="btn btn-primary btn-lg" title="Facebook"><i class="fa fa-facebook"></i></a>
//     <!--<a href="#" class="btn btn-info btn-lg" title="Twitter"><i class="fa fa-twitter"></i></a>
//     <a href="#" class="btn btn-danger btn-lg" title="Google"><i class="fa fa-google"></i></a>-->
//   </div>
//   <div class="or-seperator"><b>or</b></div>
//       <div class="form-group">
//         <input type="text" class="form-control input-lg" formControlName="email" placeholder="Username" required="required">
//       </div>
//   <div class="form-group">
//           <input type="password" class="form-control input-lg" formControlName="password" placeholder="Password" required="required">
//       </div>
//       <div class="form-group">
//           <button type="submit" class="btn btn-success btn-lg btn-block signin-btn">Sign in</button>
//       </div>
//       <div class="text-center small"><a href="#">Forgot Your password?</a></div>
//   </form>
//   <div class="text-center small">Don't have an account? <a href="#">Sign up</a></div>
// </section>
//   </div>
//   `
// })
// export class NgbdModalContent {
//   @Input() name;

//   constructor(public activeModal: NgbActiveModal) {}
// }

@Component({
  selector: 'login-avatar',
  templateUrl: './login-avatar.component.html',
  styleUrls: ['./login-avatar.component.scss']
})
export class LoginAvatarComponent extends CommonComponent implements OnInit {

  
  public userAccount$:Observable<any>;

  constructor(private router:Router,private modalService: NgbModal,private store: Store<State>, public translate: TranslateService) { 
    super(translate);
    
    this.userAccount$ = this.store.select(fromRoot.userLogin)
    .pipe(
      map(data => data.user),
      takeWhile(() => this.isAlive)
    );
    
    
    
    // .pipe(
    //   map(data => {
        
    //     console.log('llll'  + data.user);
    //     return data.user;
    //   }),
    //   takeWhile(() => this.destroy$));
  }

  ngOnInit() {
  }
  
  public login() {
      //visualizzal apagin adi login
      //this.store.dispatch(loginActions.goTologin())
      // console.log('login');
      this.router.navigateByUrl('/'+RoutingDataType.login);
  }

  public logout() {
    this.store.dispatch(loginActions.logout())
  }
  

 
  // //apre la finestra modale
  // public toLoginPage() {
  //   //this.router.navigateByUrl('/'+RoutingDataType.login);
  //   const modalRef = this.modalService.open(LoginDialogComponent);
  //   modalRef.componentInstance.name = 'World';
  // }

  ngOnDestroy(){
    this.isAlive = false;
  }
}
