import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeBrTag'
})
export class RemoveBrTagPipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/<br\s*\/?>/gi,' ').slice(0,200).concat('...');
  }

}
