import { Action, createReducer, on } from "@ngrx/store";
import * as LanguageActions from '../actions';
import { Language } from "../entity/language.entity";

export const homeFeatureKey = 'home';

export interface State {
  current: Language;
}

export function reducer(state: State | undefined, action: Action): any {
  return homeReducer(state, action);
}

export const initialState: State = {
  current: (navigator.language.toLowerCase().startsWith('it')
    ? 'fr'
    : 'en') as Language
};


export const homeReducer = createReducer(
  initialState,
  on(LanguageActions.setLanguage, (state, { language }) => ({
    ...state,
    current: language
  }))
);

export const getCurrentLanguage = (state: State) =>
{
  return state[homeFeatureKey].current;
};
