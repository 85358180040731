import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import * as fromRoot from 'app/+state';
import { State } from "app/+state";
import * as loginActions from 'app/+state/actions/login.actions';
import { UserSignUp } from 'app/+state/entity/user.entity';
import { AuthService } from "app/shared/services/auth.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css']
})
export class SubscribeComponent implements OnInit {
  
  public subscribeForm: FormGroup;

  subscribeError$:Observable<String>;

  private destroy:boolean = false;

  constructor(private formBuilder: FormBuilder,private authService:AuthService,private store: Store<State>) {

    this.subscribeError$ = this.store.select(fromRoot.signUpFailure)
    .pipe(
      map(data => {
        return data.errorMessage;
      })
    );
    
    
  }

  ngOnInit() {
    this.subscribeForm = this.formBuilder.group({
      //username: ["", [Validators.required, Validators.maxLength(20)]],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      firstName: ["", [Validators.required, Validators.maxLength(20)]],
      lastName: ["", [Validators.required, Validators.maxLength(20)]]
    });
  }


  public signUp() {

      //this.socialAuthService.initState.subscribe(a => console.log(a));
      let userSignUp:UserSignUp =  {
        //username: this.subscribeForm.value.username,
        email: this.subscribeForm.value.email, 
        password: this.subscribeForm.value.password,
        firstName: this.subscribeForm.value.firstName,
        lastName: this.subscribeForm.value.lastName,
       };
       this.store.dispatch(loginActions.signUp({ 
          userSignUp: userSignUp
        }));
  }

  
  ngOnDestroy(){
    this.destroy = true;  
  }
}