import { Action, createReducer, on } from '@ngrx/store';
import { PageData } from 'app/shared/models/common.model';
import * as journalActions from '../actions/journal.actions';
import { EsitoMessage } from '../entity';
import { FilterJournal, Journal, JournalPageData, WpJournalPageData } from '../entity/journal.entity';


export const journalFeatureKey = 'journal';

export interface State {
  filter: FilterJournal;
  pageData:JournalPageData;
  isLoadingJournal:boolean;
  journalLoaded:boolean;
  esito: EsitoMessage;
  dataPage:WpJournalPageData;
}

export const initialState: State = {
  filter:{
    pageNumber:1,
    pageSize:PageData.PAGE_SIZE
  },
  pageData:undefined,
  isLoadingJournal : false,
  journalLoaded : false,
  esito: undefined,
  dataPage: undefined
};


export function reducer(state: State | undefined, action: Action): any {
    return journalReducer(state, action);
  }
  
  export const journalReducer = createReducer(
    initialState,
    on(journalActions.findJournal, (state, { filter }) => ({
      ...state,
      filter:filter,
      isLoadingJournal : true,
      journalLoaded : false
    })),
    on(journalActions.findJournalSuccess, (state, { pageData }) => ({
      ...state,
      pageData: pageData,
      isLoadingJournal : false,
      journalLoaded : true
    })),
    on(journalActions.findJournalFailure, (state, { esito }) => ({
      ...state,
      pageData : undefined,
      esito : esito,
      isLoadingJournal : false,
      journalLoaded : false
    })),
    on(journalActions.loadJournalPageDataSuccess, (state, { data }) => ({
      ...state,
      dataPage : data
    })),
    on(journalActions.loadJournalPageDataFailure, (state, { data }) => ({
      ...state,
      dataPage : data
    })),
    on(journalActions.askForAnotherNumber, (state, { message }) => ({
      ...state,
    })),
    on(journalActions.askForAnotherNumberSuccess, (state) => ({
      ...state,
    })),
    on(journalActions.askForAnotherNumberFailure, (state) => ({
      ...state,
    })),
 );


 export const getJournals = (state: State) => {

    if(state.pageData) {
      return Journal.fromDTOs(state.pageData.data)
    }
    else {
      return [];
    }
    ;
  };

  export const dataPageJournal = (state: State) => {

    if(state.dataPage) {
      return state.dataPage;
    }
  }
