import { Category } from "app/shared/models/category.model";
import { AvatarDataModel, DataModel, PageData } from "app/shared/models/common.model";
import { plainToClass } from 'class-transformer';
import { Artist } from "./artist.entity";


export interface FilterOperas {
    search:string;
    categories:Category[];
    pageNumber:number;
    pageSize:number;
}

export class OperaPageData extends PageData<Opera> {}

export class Opera extends DataModel {

    title:string;
    dimension?:OperaDimension;
    price:number;
    currency:string
    description:string;
    copyright:string;
    dft_image:OperaAvatarDataModel
    artist:Artist;
    categories:Category[];
    tecnique:string;
    saled:boolean;
    //immagini per l'opera da caricare
    image1:OperaAvatarDataModel;
    image2:OperaAvatarDataModel;
    image3:OperaAvatarDataModel;
    image4:OperaAvatarDataModel;
    image5:OperaAvatarDataModel;
    

    public static fromPaginatedDTOs(dto:[]):Opera[] {

        let result:Array<Opera> = new Array();
        if(dto && dto.length > 0) {
            dto.forEach(dto => result.push(Opera.fromDTO(dto)))
        }

        //const operaPageInfo: OperaPageInfo = plainToClass(OperaPageInfo, dto['acf']);
        //return operaPageInfo;
        return result;
    
      }
    
    public static fromDTOs(dto:any[]):Opera[] {

      let result:Array<Opera> = new Array();
      if(dto && dto.length > 0) {
          dto.forEach(dto => result.push(Opera.fromDTO(dto)))
      }

      //const operaPageInfo: OperaPageInfo = plainToClass(OperaPageInfo, dto['acf']);
      //return operaPageInfo;
      return result;
  
    }

    public static fromDTO(dto: any) {

      let opera: Opera;
        if(dto[0]) {
          opera = plainToClass(Opera, dto[0]);
        } else {
          opera = plainToClass(Opera, dto);
        }

       
    
        //this.copyMatchingValues(artist,dto);
        //this.copyMatchingValues(artist.avatar,dto.avatar);
    
    
    
        /*sensor.id = dto.id;
        sensor.class = dto.classe;
        sensor.code = dto.code;
        sensor.description = dto.description;
        sensor.name = dto.name;
        sensor.latitude = dto.sensorGeoLat;
        sensor.longitude = dto.sensorGeoLong;
        sensor.sensorStatus = dto.sensorStatus;
        sensor.sensorType = dto.sensorTypeId;
        sensor.accessRight = dto.accessRight;
        sensor.observedProperties = ObservedProperty.fromDTOs(dto.observedProperties);
        sensor.dataStreams = dto.datastreams;
        sensor.ownerCustomer = Customer.fromDTO(dto.ownerCustomer);*/
        return opera;
      }
    
      //saving data to db
      public static toDTO(elem: Opera) {
        const dto: any = {};
        /*dto.id = elem.id;
        dto.classe = elem.class;
        dto.code = elem.code;
        dto.description = elem.description;
        dto.name = elem.name;
        dto.sensorGeoLat = elem.latitude;
        dto.sensorGeoLong = elem.longitude;
        dto.sensorStatus = elem.sensorStatus;
        dto.sensorTypeId = elem.sensorType;
        dto.accessRight = elem.accessRight;
        dto.observedProperties = ObservedProperty.toDTOs(elem.observedProperties);
        dto.datastreams = elem.dataStreams;
        dto.ownerCustomer = Customer.toDTO(elem.ownerCustomer);*/
        return dto;
      }
}


export class OperaAvatarDataModel extends AvatarDataModel {
    //"filename":"./assets/img/bg4.jpg";
}


export class OperaDimension {
  height:number;
  width:number;
  depth:number;
  udm:string;
}