import { Action, createReducer, on } from "@ngrx/store";
import * as eventsActions from '../actions';
import { EsitoMessage } from "../entity";
import { GenericEvent } from './../entity/events.entity';

export const eventsFeatureKey = 'events';

export interface State {
  events:GenericEvent[];
  isLoadingEvents:boolean;
  eventsLoaded:boolean;
  esito: EsitoMessage;
  subscribingEvent :boolean;
  eventSubscribed :boolean;
  unSubscribingEvent: boolean;
  eventUnsubscribed : boolean;
}

export const initialState: State = {
  events:[],
  isLoadingEvents : false,
  eventsLoaded : false,
  subscribingEvent: false,
  eventSubscribed:false,
  unSubscribingEvent: false,
  eventUnsubscribed : false,
  esito: undefined
};

//common function for load a reducer
export function reducer(state: State | undefined, action: Action): any {
  return eventsReducer(state, action);
}

export const eventsReducer = createReducer(
  initialState,
  on(eventsActions.findEvents, (state, { filter }) => ({
    ...state,
    isLoadingEvents : true,
    eventsLoaded : false
  })),
  on(eventsActions.findEventsSuccess, (state, { events }) => ({
    ...state,
    events : events,
    isLoadingEvents : false,
    eventsLoaded : true
  })),
  on(eventsActions.findEventsFailure, (state, { esito }) => ({
    ...state,
    events : undefined,
    esito : esito,
    isLoadingEvents : false,
    eventsLoaded : false
  })),
  on(eventsActions.subscribeToEvent, (state, { eventId }) => (
    {
     ...state,
     subscribingEvent: true,
     eventSubscribed: false
   }
   )),
   on(eventsActions.subscribeToEventSuccess, (state, { event }) => ({
    ...state,
    events : state.events.map((value) => value.id === event.id ? {...value, users_subscribed: event.users_subscribed} : value),
    subscribingEvent: false,
    eventSubscribed : true
  })),
  on(eventsActions.subscribeToEventFailure, (state, { esito }) => ({
    ...state,
    esito : esito,
    subscribingEvent: false,
    eventSubscribed: false
  })),
  on(eventsActions.unsubscribeToEvent, (state, { eventId }) => (
    {
     ...state,
     unSubscribingEvent: true,
     eventUnsubscribed: false
   }
   )),
   on(eventsActions.unsubscribeToEventSuccess, (state, { event }) => ({
    ...state,
    events : state.events.map((value) => value.id === event.id ? {...value, users_subscribed: event.users_subscribed} : value),
    unSubscribingEvent: false,
    eventUnsubscribed : true
  })),
  on(eventsActions.unsubscribeToEventFailure, (state, { esito }) => ({
    ...state,
    esito : esito,
    unSubscribingEvent: false,
    eventUnsubscribed: false
  })),
);




export const getGenericEvents = (state : State) => {
  return state.events;
};

//dato l'utente loggato verificare se è sottoscritto all'evento attraverso l'ID
export const isUserSubscribedToEvent = (state : State) => {
  return state.events;
};

