import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CrudService } from '../core/crud.service';
import { ErrorService } from '../core/error.service';
import { LoaderService } from '../core/loader.service';
import { BeResponse } from './../../../+state/entity/response.entyt';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends CrudService<BeResponse>{

  constructor(public httpClient: HttpClient,
    public errorService: ErrorService,
    public loaderService: LoaderService) {
    super(httpClient, `${environment.endpoint}`, '/acf/v3', errorService, loaderService);
    //this.search$ = this._search.asObservable();
  }

  public loadArtistsCategories():Observable<BeResponse> {
    
    return this.httpClient
      .get(`${this.baseUrl}${this.endpoint}/artist-category/`)
      .pipe(
        map((data: any) => {
          const result:BeResponse = new BeResponse();
          result.code = "artists.categoriesLoaded";
          result.data = data;
          result.message = "CategoriesLoaded";
          result.statusCode = "200";
          result.success = true;
          return result;
        }), 
        catchError((err) => {
          this.error(err);
          throw err;
        }),
        //finalize(() => this.loaderService.hide())
        );
  }

  public loadOperasCategories():Observable<BeResponse> {
    return this.httpClient
      .get(`${this.baseUrl}${this.endpoint}/opera-category-slug/`)
      .pipe(
        map((data: any) => {
          const result:BeResponse = new BeResponse();
          result.code = "operas.categoriesLoaded";
          result.data = data;
          result.message = "CategoriesLoaded";
          result.statusCode = "200";
          result.success = true;
          return result;
        }), 
        catchError((err) => {
          this.error(err);
          throw err;
        }),
        //finalize(() => this.loaderService.hide())
        );
  }

  // public loadArtistsCategories():Observable<Category[]> {
  //   //this.loaderService.show();

  //   //compose pageParams
  //   //const  params = new  HttpParams({fromString:  '_page=1&_limit=1'});
  //   return this.httpClient
  //     .get(`${this.baseUrl}${this.endpoint}/artist-category/`)
  //     .pipe(
  //       map((data: any) => Category.fromDTOs(data)),
  //       catchError((err) => {
  //         this.error(err);
  //         throw err;
  //       }),
  //       //finalize(() => this.loaderService.hide())
  //       );
  // }
}
