import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslateSharedService {

  constructor(public translateService:TranslateService) { }


  use(lang: string): Observable<any> {
    return this.translateService.use(lang);
  }
}
