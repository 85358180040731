import { DOCUMENT, Location } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import 'rxjs/add/operator/filter';
import { Subscription } from 'rxjs/Subscription';
import { NavbarComponent } from './shared/navbar/navbar.component';

//var FontFaceObserver = require('fontfaceobserver');

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,OnDestroy {
    private _router: Subscription;
    @ViewChild(NavbarComponent) navbar: NavbarComponent;

    private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

    constructor( private renderer : Renderer2, 
                 private router: Router, 
                 @Inject(DOCUMENT,) private document: any, 
                 private element : ElementRef, 
                 public location: Location,
                 private readonly translate: TranslateService,
                 /*,private ccService: NgcCookieConsentService*/) {

                 this.translate.setDefaultLang('en');

    }
    ngOnInit() {
        var navbar : HTMLElement = this.element.nativeElement.children[0].children[0];
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            if (window.outerWidth > 991) {
                window.document.children[0].scrollTop = 0;
            }else{
                window.document.activeElement.scrollTop = 0;
            }
            this.navbar.sidebarClose();
        });
        this.renderer.listen('window', 'scroll', (event) => {
            const number = window.scrollY;
            if (number > 150 || window.pageYOffset > 150) {
                // add logic
                navbar.classList.remove('navbar-transparent');
            } else {
                // remove logic
                navbar.classList.add('navbar-transparent');
            }
        });
        var ua = window.navigator.userAgent;
        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            var version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
        if (version) {
            var body = document.getElementsByTagName('body')[0];
            body.classList.add('ie-background');

        }


        // var font = new FontFaceObserver('glimmer');

        // font.load().then(function () {
        // console.log('Output Glimmer has loaded.');
        // }).catch(function () {
        // console.log('Output Glimmer not to load.');
        // });


        // //gestioen cockie consent on entre on the site

        // this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
        // // Override default messages with the translated ones
        // this.ccService.getConfig().content.header = "Cookies used on the website!";
        // this.ccService.getConfig().content.message = "This website uses cookies to ensure you get the best experience on our website.";
        // this.ccService.getConfig().content.dismiss = "Got it!";
        // this.ccService.getConfig().content.allow = "Allow cookies";
        // this.ccService.getConfig().content.deny = "Decline";
        // this.ccService.getConfig().content.link = "Learn more";
        // this.ccService.getConfig().content.policy = "Cookie Policy";

        // this.ccService.destroy(); // remove previous cookie bar (with default messages)
        // this.ccService.init(this.ccService.getConfig()); // update config with translated messages



        // this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
        //     () => {
        //       // you can use this.ccService.getConfig() to do stuff...
        //     });
      
        //   this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
        //     () => {
        //       // you can use this.ccService.getConfig() to do stuff...
        //     });
      
        //   this.initializeSubscription = this.ccService.initialize$.subscribe(
        //     (event: NgcInitializeEvent) => {
        //       // you can use this.ccService.getConfig() to do stuff...
        //     });
      
        //   this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        //     (event: NgcStatusChangeEvent) => {
        //       // you can use this.ccService.getConfig() to do stuff...
        //     });
      
        //   this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        //     () => {
        //       // you can use this.ccService.getConfig() to do stuff...
        //     });
      
        //     this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        //     (event: NgcNoCookieLawEvent) => {
        //       // you can use this.ccService.getConfig() to do stuff...
        //     });





    }
    removeFooter() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        titlee = titlee.slice( 1 );
        if(titlee === 'signup' || titlee === 'nucleoicons'){
            return false;
        }
        else {
            return true;
        }
    }

    ngOnDestroy() {
        // unsubscribe to cookieconsent observables to prevent memory leaks
        this.popupOpenSubscription.unsubscribe();
        this.popupCloseSubscription.unsubscribe();
        this.initializeSubscription.unsubscribe();
        this.statusChangeSubscription.unsubscribe();
        this.revokeChoiceSubscription.unsubscribe();
        this.noCookieLawSubscription.unsubscribe();
      }
}
