import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private readonly dompurifySanitizer: NgDompurifySanitizer) {}

  transform(dirty: string): string {
    return  this.dompurifySanitizer.sanitize(SecurityContext.HTML, dirty);
  }

}
