import { Component } from '@angular/core';
import { SeoService } from 'app/shared/services/core/seo.service';

@Component({
  selector: 'seo-noindex',
  template: '',
})
export class SeoNoIndexComponent {

  constructor(
    private seoService: SeoService,
  ) {
    this.seoService.setNoIndex();
  }
}
