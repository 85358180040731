import { Action, createReducer, on } from "@ngrx/store";
import { Category } from "app/shared/models/category.model";
import * as operasActions from "../actions/operas.actions";
import { EsitoMessage } from "../entity/contacts.entity";
import { FilterOperas, Opera, OperaPageData } from "../entity/operas.entity";

export const operasFeatureKey = "operas";

export interface State {
  filter: FilterOperas;
  pageData:OperaPageData;
  isLoadingOperas: boolean;
  operasLoaded: boolean;
  esito: EsitoMessage;
  categories: Category[];
  isLoadingCategories: boolean;
  categoriesLoaded: boolean;
}

export const initialState: State = {
  filter: {
    search:'',
    categories:[],
    pageNumber:1,
    pageSize:1
  },
  pageData: undefined,
  categories: [],
  isLoadingOperas: false,
  operasLoaded: false,
  esito: undefined,
  isLoadingCategories: false,
  categoriesLoaded: false,
};

//common function for load a reducer
export function reducer(state: State | undefined, action: Action): any {
  return operasReducer(state, action);
}

export const operasReducer = createReducer(
  initialState,
  on(operasActions.findOperas, (state, { filter }) => ({
    ...state,
    filter: filter,
    isLoadingOperas: true,
    operasLoaded: false,
  })),
  on(operasActions.findOperasSuccess, (state, { pageData }) => ({
    ...state,
    pageData: pageData,
    isLoadingOperas: false,
    operasLoaded: true,
  })),
  on(operasActions.findOperasFailure, (state, { esito }) => ({
    ...state,
    //pageData: undefined,
    esito: esito,
    isLoadingOperas: false,
    operasLoaded: false,
  })),
  on(operasActions.findOperasCategories, (state) => ({
    ...state,
    isLoadingCategories: true,
    categoriesLoaded: false,
  })),
  on(operasActions.findOperasCategoriesSuccess, (state, { categories }) => ({
    ...state,
    categories: categories,
    isLoadingCategories: false,
    categoriesLoaded: true,
  })),
  on(operasActions.findOperasCategoriesFailure, (state, { esito }) => ({
    ...state,
    categories: undefined,
    isLoadingCategories: false,
    categoriesLoaded: false,
  })),
  on(operasActions.viewMoreOperas, (state, {filter}) => ({
    ...state,
    filter:filter,
    isLoadingOperas: true,
    operasLoaded: false,
  })),
  on(operasActions.viewMoreOperasSuccess, (state, { pageData }) => ({
    ...state,
    pageData:{...state.pageData,
              data: state.pageData.data.concat(pageData.data),
              pageCount: pageData.pageCount,
              pageNumber: pageData.pageNumber
             },
    isLoadingOperas: false,
    operasLoaded: true,
  })),
  on(operasActions.viewMoreOperasFailure, (state, { esito }) => ({
    ...state,
    esito: esito,
    isLoadingOperas: false,
    operasLoaded: false,
  })),
);

export const getOperas = (state: State) => {

  if(state.pageData) {
    return Opera.fromDTOs(state.pageData.data)
  }
  else {
    return [];
  }
  ;
};


export const getOperasCategories = (state: State) => {
    return state.categories;
  };

export const getFilterOperas = (state: State) => {
    return state.filter;
};

export const showMoreOperas = (state: State) => {

  if(state.pageData) {
    return state.pageData.pageNumber < state.pageData.pageCount;
  }
  return false;
}