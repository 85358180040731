import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Artist } from 'app/+state/entity/artist.entity';
import { Opera } from 'app/+state/entity/operas.entity';
import { CommonComponent } from 'app/shared/components/common/common.component';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-artist-gallery',
  templateUrl: './artist-gallery.component.html',
  styleUrls: ['./artist-gallery.component.scss']
})
export class ArtistGalleryComponent extends CommonComponent implements OnInit {

  @Input()
  artist:Artist;

  @Input() 
  operaId:number;


  operaSelected:Opera;

  @ViewChild('viewOpera')
  viewOperaRef: ElementRef;


  constructor(public translate: TranslateService) {
    super(translate);
   }

  ngOnInit(): void {
    if(!(this.operaId)) {
      if(this.artistWithOperas()) {
        this.operaSelected = this.artist.operas[0];
        this.operaId = this.operaSelected.id;
      }
    }
    else {

      const opera =  this.artist.operas.filter(opera => opera.id === this.operaId)[0];
      this.operaSelected = opera ? opera : this.artist.operas[0];
    }
  }


  public artistWithOperas():boolean {
    return this.artist.operas && this.artist.operas.length > 0;
  }

  public artistWithMoreOperas():boolean {
    return this.artist.operas && this.artist.operas.length > 1;
  }

  public viewOperaDetail(opera:Opera) {
      this.operaSelected = opera;
      this.viewOperaRef.nativeElement.scrollIntoView();
  }


  /************ Carousel Option************************/
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    center: false,
    nav: false,
    margin: 0,
    autoWidth: true,
    //stagePadding:2,
    //navContainer: "custom",
    //navText: ["", ""],
    items: 5,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 3,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
  };

}
