import { TranslateService } from "@ngx-translate/core";

export class CommonComponent  {

  protected isAlive:boolean = true;

  constructor(public translate:TranslateService) { 
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }

  onClickExternal(urlExternal = null) {

    console.log(urlExternal);
    if (urlExternal) {
      window.open(urlExternal, "_blank");
    }
  }

}
