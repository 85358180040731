import {
  HttpErrorResponse,
  HttpEvent, HttpHandler,

  HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../../shared/services/auth.service';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService:AuthService) {}


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.getJwtToken()) {
      request = this.addToken(request, this.authService.getJwtToken());
    }

    
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(request, next);
      } else {
        return throwError(error) as Observable<HttpEvent<any>>;
      }
    }),
    );
  }

  private addToken(request: HttpRequest<any>, token: string):HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.jwt);
          return next.handle(this.addToken(request, token.jwt));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  //inseriesc il bearer nell'header per ogni richiesta autenticata verso lgi endpoint
  /*
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.getJWTToken();
    request = request.clone({
      url:  request.url,
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    return next.handle(request);
  }*/

//   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     // add auth header with jwt if account is logged in and request is to the api url
//     // const jwtToken = this.authService.obtainAccessToken()();
//     // //const isLoggedIn = account?.extraInfo?.token;
//     // const isApiUrl = request.url.startsWith(environment.endpoint);
//     // if (jwtToken && isApiUrl) {
//     //     request = request.clone({
//     //         setHeaders: { Authorization: `Bearer ${jwtToken}` }
//     //     });
//     // }

//     //verifica se l'utente è presente se presente controlla che il token è ancora valido , se non vlaido richied eun nuovo token 
//     //al backend per la comunicazione con la rest api.
//     return this.authService.checkUserIsLogged()
//     .pipe(
//       tap(() => this.loaderService.show()),
//       concatMap(bearer => { 
//         //bearer protrebbe essere null, quindi errore

//         if(bearer) {
//           const _request = request.clone({
//             setHeaders: { Authorization: `Bearer ${bearer}` }
//           });
//           return next.handle(_request);
//         }

        
//         return next.handle(request)
//         //.pipe(
//             }),
//         tap(() => this.loaderService.hide()),
//         catchError(error => { 
//           this.loaderService.hide();
//           console.log(error);
//           throw(error);})
       
//     );
//   }
 }



// intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
//     {
//         let token = localStorage.getItem('api-token');

//         if (token) {
//             req = req.clone({
//                 setHeaders: {
//                     'api-token': token
//                 }
//             } );
//         }

//         return next.handle(req).catch(err => {
//             console.log(err);
//             if (err.status === 401) {
//                 if (err.error.message == "Token is exp") {
//                     //Genrate params for token refreshing
//                   let params = {
//                     token: token,
//                     refreshToken: localStorange("refreshToken");
//                   };
//                   return this.http.post('localhost:8080/auth/refresh', params).flatMap(
//                     (data: any) => {
//                       //If reload successful update tokens
//                       if (data.status == 200) {
//                         //Update tokens
//                         localStorange.setItem("api-token", data.result.token);
//                         localStorange.setItem("refreshToken", data.result.refreshToken);
//                         //Clone our fieled request ant try to resend it
//                         req = req.clone({
//                           setHeaders: {
//                             'api-token': data.result.token
//                           }
//                         });
//                         return next.handle(req).catch(err => {
//                           //Catch another error
//                         });
//                       }else {
//                         //Logout from account
//                       }
//                     }
//                   );
//                 }else {
//                     //Logout from account or do some other stuff
//                 }
//             }
//             return Observable.throw(err);
//         });

      
//     }