import { Pipe, PipeTransform } from '@angular/core';
import { OperaDimension } from 'app/+state/entity/operas.entity';

@Pipe({
  name: 'operaDimension'
})
export class OperaDimensionPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(dimension:OperaDimension) {
    if(dimension) {
      return (dimension.width ? dimension.width : "0")  + ' x ' + (dimension.height ? dimension.height : "0") + ' x ' + (dimension.depth ? dimension.depth : "0") + ' ' + (dimension.udm ? dimension.udm : "mm");
    }
    else {
      return "";
    }
  }

}
